import { type SourceConfig } from 'bitmovin-player'
import { type ErrorMessageOverlay, type Label, ControlBar, SettingsPanelItem, Button, SeekBarLabel, SeekBar, CastToggleButton } from 'bitmovin-player-ui'
import { type NPOTag, type PageTracker } from '@npotag/tag'
import { ButtonConfig } from 'bitmovin-player-ui/dist/js/framework/components/button'

export interface Profile {
    profileName: string
    drm: string
}

export interface StreamObject {
    stream: StreamObject_Stream
    metadata: StreamObject_Metadata
    assets: StreamObject_Assets
}

export interface ApiPayload {
    baseURL: string
    jwt: string
    data: Record<string, unknown>
}

export interface DRMProfile {
    profileName: string
    drm: string
}

export interface NPOTagObject {
    npoTagInstance: NPOTag | undefined
    heartbeatInterval: number | undefined
    pageTracker: PageTracker | null
}

interface StreamObject_Stream {
    avType: string
    drmToken?: string
    drmType: string
    streamProfile: string
    streamURL: string
    isLiveStream?: boolean
    hasDvrWindow?: boolean
}

interface Subtitle {
    iso: string
    location: string
    name: string
}

interface StreamObject_Assets {
    scrubbingThumbnail: string
    subtitles: Subtitle[] | null
    preroll?: string
}

interface StreamObject_Metadata {
    broadcaster?: string
    broadcasters?: string[]
    country?: string[]
    credits?: string[]
    description: string
    duration?: string
    genre?: string[]
    isDubbed?: boolean
    language?: string[]
    nicam?: string[] | null
    poster?: string
    prid?: string
    sourceIP?: string
    ageRating?: string
    title: string
    hasPreroll?: string
}

export interface Section {
    cssClasses?: string[]
    start: number
    end: number
    title: string
}

export interface Fragments {
    sections: Section[]
    startAtBeginOfFragment?: boolean
    startAtFragment?: number
    stopAtEndOfFragment?: boolean
}

/*
* @interface StreamOptions
* @property {SourceConfig} sourceConfig - The source configuration for the player
* @property {Fragments} fragments - The fragments object for playing a specific fragment of the vod
* @property {number} startOffset - The start offset for the player in seconds
* @property {boolean} liveOffset - If set to true the player will start at the timestamp supplied in liveProgramTime
* @property {number} liveProgramTime - The unix timestamp to rewind to for a live stream
* @property {string} endpoint - The stream API endpoint
* @property {string} preferredDRM - The preferred DRM for the player implementation
* @property {string} prid - PRID of the stream to be played
*/
export interface StreamOptions {
    sourceConfig?: SourceConfig
    fragments?: Fragments
    startOffset?: number
    liveOffset?: boolean
    liveProgramTime?: number
    endpoint?: string
    preferredDRM?: string
    prid?: string
}

export interface UIComponents {
    errorMessageOverlay?: ErrorMessageOverlay
    nicam?: Label<{ text: string, cssClass: string }>
    subtitlesButton?: SettingsPanelItem | undefined
    qualityButton?: SettingsPanelItem | undefined
    watchFromStartButton?: Button<ButtonConfig> | undefined
    controlbar?: ControlBar | undefined
    adbutton?: Button<ButtonConfig> | undefined
    adlabel?: Label<{ text: string, cssClass: string }> | undefined
    seekBar?: SeekBar | undefined  
    chromeCastButton?: CastToggleButton | undefined 
}

export interface CustomMessageHandlerInterface {
    defaultActionRequired?: boolean;
    isChecked?: boolean;
}

export enum CustomMessages {
    CLICK_CAST = 'castButtonClick',
    CLICK_GO_BACK_TO_LIVE = 'clickGoBackToLive',
    CLICK_PLAY_NEXT = 'clickPlayNext',
    CLICK_WATCH_FROM_START = 'clickWatchFromStart',
    DO_PLAY_NEXT = 'doPlayNext',
    JAVASCRIPT_READY = 'javascriptReady',
    SET_STREAM_LINK = 'setStreamLink',
    TOGGLE_AIRPLAY = 'toggleAirplay',
    TOGGLE_FULLSCREEN = 'toggleFullScreen',
    TOGGLE_FULLSCREEN_UI = 'toggleFullscreenUI',
    TOGGLE_MUTE = 'toggleMute',
    TOGGLE_PIP = 'togglePiP',
    TOGGLE_PLAY_PAUSE = 'togglePlayPause',
    TOGGLE_SETTINGS_PANEL = 'toggleSettingsPanel',
}
