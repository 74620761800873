import {
    ControlBar,
    Container,
    PlaybackTimeLabel,
    PlaybackTimeLabelMode,
    SeekBar,
    SeekBarLabel,
    PlaybackToggleButton,
    VolumeToggleButton,
    VolumeSlider,
    Spacer,
    PictureInPictureToggleButton,
    AirPlayToggleButton,
    CastToggleButton,
    SettingsToggleButton,
    FullscreenToggleButton,
    type SettingsPanel
} from 'bitmovin-player-ui'
import { airPlaySupported } from '../../../js/utilities/utilities'
import type NpoPlayer from '../../../npoplayer'
import { PlayerEvent } from 'bitmovin-player'
import { createForwardButton, createRewindButton } from './buttons'

export function createControlBar (
    npoplayer: NpoPlayer,
    settingsPanel: SettingsPanel
) {
    const player = npoplayer.player

    const rewindButton = createRewindButton(player)
    const forwardButton = createForwardButton(player)

    const seekBar = new SeekBar({ label: new SeekBarLabel() })
    npoplayer.uiComponents.seekBar = seekBar

    const chromeCastButton = new CastToggleButton({
        hidden: true
    })

    player?.on(PlayerEvent.CastAvailable, () => {
        if(player.isCastAvailable()) chromeCastButton.show()
    })

    const controlbar = new ControlBar({
        components: [
            settingsPanel,
            new Container({
                components: [
                    new PlaybackTimeLabel({
                        timeLabelMode: PlaybackTimeLabelMode.CurrentTime,
                        hideInLivePlayback: true,
                        cssClasses: ['current-time']
                    }),
                    seekBar,
                    new PlaybackTimeLabel({
                        timeLabelMode: PlaybackTimeLabelMode.TotalTime,
                        cssClasses: ['total-time']
                    })
                ],
                cssClasses: ['controlbar-top']
            }),
            new Container({
                components: [
                    new PlaybackToggleButton(),
                    new VolumeToggleButton(),
                    new VolumeSlider(),
                    rewindButton,
                    forwardButton,
                    new Spacer(),
                    ...(document.pictureInPictureEnabled && /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? [new PictureInPictureToggleButton()] : []),
                    ...(airPlaySupported() ? [new AirPlayToggleButton()] : []),
                    chromeCastButton,
                    new SettingsToggleButton({ settingsPanel }),
                    new FullscreenToggleButton()
                ],
                cssClasses: ['controlbar-bottom']
            })
        ],
        cssClasses: ['ui-controlbar-bottom']
    })


    npoplayer.uiComponents.controlbar = controlbar

    return controlbar
}
