/**
 * @function processPlayerConfig
 * @description This function is called by the NpoPlayer constructor to enrich the player configuration object 
 * with default values where none were set by the user.
 * @param {NpoPlayer} npoplayer - The NpoPlayer instance
 * @param {PlayerConfig} playerConfig - The player configuration object that was created by the user
 * @returns The processed player configuration object
 */

import { type PlayerConfig, LogLevel, GoogleCastRemoteControlConfig } from "bitmovin-player";
import NpoPlayer from "npoplayer";
import { isSafari } from '../../checks/safari'

export function processPlayerConfig(npoplayer:NpoPlayer, playerConfig:PlayerConfig) {

    let processedConfig = playerConfig

    if (isSafari(npoplayer.isFairPlayDrmSupported())) {
        // Disable autoplay on safari
        processedConfig.playback = {
            autoplay: false
        }

        // Potential fix for DRM issues on safari
        processedConfig.tweaks = {
            fairplay_ignore_duplicate_init_data_key_errors: true
        }
    }

    processedConfig.ui = false

    const defaultRemoteControlConfig = {
        type: 'googlecast',
        receiverApplicationId: 'CC38EB17',
        receiverVersion: 'v3',
        messageNamespace: 'urn:x-cast:com.bitmovin.player.caf',
        customReceiverConfig: {
            drm: npoplayer.drmProfile.drm,
            profileName: npoplayer.drmProfile.profileName,
            jwt: npoplayer.jwt
        }
    };

    const userRemoteControlConfig = processedConfig.remotecontrol || defaultRemoteControlConfig;

    
    const mergedRemoteControlConfig = {
        ...defaultRemoteControlConfig,
        ...userRemoteControlConfig,
        customReceiverConfig: {
            ...defaultRemoteControlConfig.customReceiverConfig,
            ...userRemoteControlConfig.customReceiverConfig
        }
    };

    processedConfig.remotecontrol = mergedRemoteControlConfig as GoogleCastRemoteControlConfig;

    if(!processedConfig.logs) processedConfig.logs = {
        level: LogLevel.ERROR
    }

    //creating this node enables the PlayerAdvertisingAPI
    processedConfig.advertising = {}
    
    return processedConfig
}