import { AirPlayToggleButton, Button, CastToggleButton, FullscreenToggleButton, PictureInPictureToggleButton, PlaybackToggleButton, SettingsPanel, SettingsToggleButton, VolumeToggleButton } from 'bitmovin-player-ui'
import { sendCustomMessage } from '../../nativemobileuicontainer'
import { CustomMessageHandlerInterface, CustomMessages } from '../../../../types/interfaces'
import { PlayerAPI } from 'bitmovin-player'

export function createPlayNextButton () {
    const playNextButton = new Button({
        cssClass:
            'ui-hugeplaynextbacktogglebutton ui-playNextButton bmpui-ui-button',
        text: 'De volgende aflevering wordt zo afgespeeld...',
        hidden: false
    })

    const playNextButtonClickHandler = () => {
        const result: CustomMessageHandlerInterface = sendCustomMessage(CustomMessages.CLICK_PLAY_NEXT)
        if (result?.defaultActionRequired === false) {
            console.warn('Default action for "playNextButton" not overwritten yet')
        }
    }

    playNextButton.onClick.subscribe(() => {
        playNextButtonClickHandler()
    })

    playNextButton.onClick.unsubscribe(() => {
        playNextButtonClickHandler()
    })

    return playNextButton
}

export function createWatchFromStartButton () {
    const watchFromStartButton = new Button({
        cssClass: 'ui-textbutton ui-watchfromstartbutton bmpui-ui-button',
        text: 'Kijk vanaf het begin',
        hidden: true
    })

    const watchFromStartButtonClickHandler = () => {
        const result: CustomMessageHandlerInterface = sendCustomMessage(CustomMessages.CLICK_WATCH_FROM_START)
        if (result?.defaultActionRequired === false) {
            console.warn('Default action for "watchFromStartButton" not overwritten yet')
        }
    }

    watchFromStartButton.onClick.subscribe(() => {
        watchFromStartButtonClickHandler()
    })

    watchFromStartButton.onClick.unsubscribe(() => {
        watchFromStartButtonClickHandler()
    })

    return watchFromStartButton
}

export function createGoBackLiveButton (player: PlayerAPI) {
    const goBackLiveButton = new Button({
        cssClass: 'ui-textbutton ui-backtolivebutton bmpui-ui-button',
        text: 'Terug naar live',
        hidden: true
    })

    const goBackLiveButtonClickHandler = () => {
        const result: CustomMessageHandlerInterface = sendCustomMessage(CustomMessages.CLICK_GO_BACK_TO_LIVE)
        if (result.defaultActionRequired === false  && player.isLive()) {
            player.timeShift(0)
        }
    }

    goBackLiveButton.onClick.subscribe(() => {
        goBackLiveButtonClickHandler()
    })

    goBackLiveButton.onClick.unsubscribe(() => {
        goBackLiveButtonClickHandler()
    })

    return goBackLiveButton
}

export function createSettingsButton (settingsPanel: SettingsPanel) {
    const customSettingsToggleButton = new SettingsToggleButton({ settingsPanel })

    const settingsButtonToggleOnHandler = () => {
        const result: CustomMessageHandlerInterface = sendCustomMessage(CustomMessages.TOGGLE_SETTINGS_PANEL)
        if (result.defaultActionRequired === false) {
            settingsPanel.hide()
            customSettingsToggleButton.off()
        }
    }

    customSettingsToggleButton.onToggleOn.subscribe(() => {
        settingsButtonToggleOnHandler()
    })

    customSettingsToggleButton.onToggleOn.unsubscribe(() => {
        settingsButtonToggleOnHandler()
    })

    return customSettingsToggleButton
}

export function createPlaybackToggleButton () {
    const customPlayerbackToggleButton = new PlaybackToggleButton({
        cssClass: 'ui-playbacktogglebutton'
    })

    const playbackToggleHandler = () => {
        const result: CustomMessageHandlerInterface = sendCustomMessage(CustomMessages.TOGGLE_PLAY_PAUSE, { isChecked: customPlayerbackToggleButton.isOn() })
        if (result?.defaultActionRequired === false) {
            console.warn('Default action for "PlaybackToggleButton" not overwritten yet')
        }
    }

    customPlayerbackToggleButton.onToggle.subscribe(() => {
        playbackToggleHandler()
    })

    customPlayerbackToggleButton.onToggle.unsubscribe(() => {
        playbackToggleHandler()
    })

    return customPlayerbackToggleButton
}

export function createVolumeToggleButton () {
    const customVolumeToggleButton = new VolumeToggleButton()

    const volumeToggleHandler = () => {
        const result: CustomMessageHandlerInterface = sendCustomMessage(CustomMessages.TOGGLE_MUTE, { isChecked: customVolumeToggleButton.isOn() })
        if (result?.defaultActionRequired === false) {
            console.warn('Default action for "VolumeToggleButton" not overwritten yet')
        }
    }

    customVolumeToggleButton.onToggle.subscribe(() => {
        volumeToggleHandler()
    })

    customVolumeToggleButton.onToggle.unsubscribe(() => {
        volumeToggleHandler()
    })

    return customVolumeToggleButton
}

export function createPictureInPictureToggleButton () {
    const customPictureInPictureToggleButton = new PictureInPictureToggleButton()

    const pictureInPictureToggleHandler = () => {
        const result: CustomMessageHandlerInterface = sendCustomMessage(CustomMessages.TOGGLE_PIP, { isChecked: customPictureInPictureToggleButton.isOn() })
        if (result?.defaultActionRequired === false) {
            console.warn('Default action for "PictureInPictureToggleButton" not overwritten yet')
        }
    }

    customPictureInPictureToggleButton.onToggle.subscribe(() => {
        pictureInPictureToggleHandler()
    })

    customPictureInPictureToggleButton.onToggle.unsubscribe(() => {
        pictureInPictureToggleHandler()
    })

    return customPictureInPictureToggleButton
}

export function createAirPlayToggleButton () {
    const customAirPlayToggleButton = new AirPlayToggleButton()

    const airPlayToggleHandler = () => {
        const result: CustomMessageHandlerInterface = sendCustomMessage(CustomMessages.TOGGLE_AIRPLAY, { isChecked: customAirPlayToggleButton.isOn() })
        if (result?.defaultActionRequired === false) {
            console.warn('Default action for "AirPlayToggleButton" not overwritten yet')
        }
    }

    customAirPlayToggleButton.onToggle.subscribe(() => {
        airPlayToggleHandler()
    })

    customAirPlayToggleButton.onToggle.unsubscribe(() => {
        airPlayToggleHandler()
    })

    return customAirPlayToggleButton
}

export function createCastToggleButton () {
    const customCastToggleButton = new CastToggleButton()

    const castToggleHandler = () => {
        const result: CustomMessageHandlerInterface = sendCustomMessage(CustomMessages.TOGGLE_AIRPLAY, { isChecked: customCastToggleButton.isOn() })
        if (result?.defaultActionRequired === false) {
            console.warn('Default action for "CastToggleButton" not overwritten yet')
        }
    }

    customCastToggleButton.onToggle.subscribe(() => {
        castToggleHandler()
    })

    customCastToggleButton.onToggle.unsubscribe(() => {
        castToggleHandler()
    })

    return customCastToggleButton
}

export function createFullscreenToggleButton () {
    const customFullscreenToggleButton = new FullscreenToggleButton()

    const fullscreenToggleHandler = () => {
        const result: CustomMessageHandlerInterface = sendCustomMessage(CustomMessages.TOGGLE_FULLSCREEN, { isChecked: customFullscreenToggleButton.isOn() })
        if (result?.defaultActionRequired === false) {
            console.warn('Default action for "FullscreenToggleButton" not overwritten yet')
        }
    }

    customFullscreenToggleButton.onToggle.subscribe(() => {
        fullscreenToggleHandler()
    })

    customFullscreenToggleButton.onToggle.unsubscribe(() => {
        fullscreenToggleHandler()
    })

    return customFullscreenToggleButton
}