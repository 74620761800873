import { PlayerAPI } from 'bitmovin-player'
import { setFragments } from '../../../fragments/setfragments'
import { UIManager } from 'bitmovin-player-ui'
import { Fragments, Section } from 'types/interfaces'

export const addFragments: any = async (player: PlayerAPI, uiManager: UIManager, section: Section) => {
    const fragments: Fragments = {
        sections: [section],
    }
  
    setFragments(player, uiManager, fragments)
}