import { AdBreakConfig, AdTagType, PlayerAPI, PlayerEvent } from "bitmovin-player"
import NpoPlayer from "npoplayer"
import { StreamObject } from "types/interfaces"

/*
* @function handlePreRolls
* @description Handles ster preroll ads for the player
* @param {PlayerAPI} playerapi - The player api
* @param {StreamObject} streamObject - The stream object
* @param {NpoPlayer} npoplayer - The npoplayer
* @returns {void}
*/

export async function handlePreRolls(playerapi: PlayerAPI, streamObject: StreamObject, npoplayer: NpoPlayer) {
    if (streamObject.metadata.hasPreroll == "false" || streamObject.assets.preroll == null) return

    let prerollUrl = streamObject.assets.preroll
    let adIndex:number = 0
    let totalAds: number = 0
    let currentClickListener: (() => void) | null = null   
    let adUiSet:boolean = false

    /*
    * @function handleSourceLoaded
    * @description If we have a preroll, schedule it to play on source loaded
    * @returns {void}
    */
    async function handleSourceLoaded() {
        const advertConfig: AdBreakConfig = {
            tag: {
                url: prerollUrl,
                type: AdTagType.VAST
            },
            id: 'Ad',
            position: 'pre',
        };
        await playerapi.ads.schedule(advertConfig)
    }
    playerapi.on(PlayerEvent.SourceLoaded, handleSourceLoaded);

    async function handlePlay() {
        attemptSetAdUi();      
    }
    playerapi.on(PlayerEvent.Play, handlePlay)

    /*
    * @function setAdUi
    * @description Sets the ad ui and initializes the ad button and label.
    * @returns {boolean}
    * @returns {void}
    */
    function setAdUi(){
        let activeAdBreak = playerapi.ads.getActiveAdBreak();
        if (!activeAdBreak || !activeAdBreak.ads) return false;

        npoplayer.uiManager?.release();
        npoplayer.createUIManager(playerapi, 'ad');
        adUiSet = true

        let currentAd = activeAdBreak.ads[0]
        adIndex = 1
        totalAds = activeAdBreak.ads.length
        
        currentClickListener = () => {
            window.open(currentAd.clickThroughUrl, '_blank')
            playerapi.pause()
        }

        npoplayer.uiComponents.adbutton?.show()
        npoplayer.uiComponents.adbutton?.onClick.subscribe(currentClickListener)
        npoplayer.uiComponents.adlabel?.setText(`Advertentie 1 van ${activeAdBreak.ads.length}`)
        return true;
    }

    /*
    * @function attemptSetAdUi
    * @description Attempts to set the ad ui. If it fails, it will try again 10 times.
    * This is because if we set the ad ui after the adBreakStarted event, the ui will not function properly.
    * We have to check if the UI is set because if the user has an adblocker, the scheduled ad will not resolve
    * causing the UI to be set on regular content otherwise.
    * @returns {void}
    */
    function attemptSetAdUi(attemptsLeft: number = 10) {
        if (attemptsLeft <= 0) return;

        const isUiSet = setAdUi();

        if (!isUiSet) {
            setTimeout(() => {
                attemptSetAdUi(attemptsLeft - 1);
            }, 200);
        }
    }

    /*
    * @function handleAdBreakStarted
    * @description sets adBreakActive to true, in the case that the ad UI was not set, set it.
    * @returns {void}
    */

    function handleAdBreakStarted() {
        let activeAdBreak = playerapi.ads.getActiveAdBreak()
        if (!activeAdBreak || !activeAdBreak.ads) {
            console.error('No active ad break data found')
            return
        }
        if(!adUiSet) setAdUi()
        npoplayer.adBreakActive = true
    }
    playerapi.on(PlayerEvent.AdBreakStarted, handleAdBreakStarted)

    /*
    * @function handleAdFinished
    * @description When the ad finishes, check if there are more ads in the adBreak.
    * If so, set the click listener for the ad button and set the label text.
    * If not, handle the adBreakFinished event.
    * @returns {void}
    */
    function handleAdFinished() {
        let activeAdBreak = playerapi.ads.getActiveAdBreak()
        if (!activeAdBreak || !activeAdBreak.ads) {
            console.error('No active ad break data found')
            return
        }
        if (adIndex >= totalAds) {
            handleAdBreakFinished()
            return
        }

        let nextAd = activeAdBreak.ads[adIndex]
        adIndex++

        if (currentClickListener) {
            npoplayer.uiComponents.adbutton?.onClick.unsubscribe(currentClickListener)
        }

        currentClickListener = () => {
            window.open(nextAd.clickThroughUrl, '_blank')
            playerapi.pause()
        }

        npoplayer.uiComponents.adbutton?.onClick.subscribe(currentClickListener)
        npoplayer.uiComponents.adlabel?.setText(`Advertentie ${adIndex} van ${activeAdBreak.ads.length}`)
    }
    playerapi.on(PlayerEvent.AdFinished, handleAdFinished)

    /*
    * @function handleAdBreakFinished
    * @description When the adBreak finishes, hide the ad button and label.
    * Also release the ad uiManager, create a new default uiManager and remove the eventlisteners.
    * @returns {void}
    */
    function handleAdBreakFinished() {
        npoplayer.adBreakActive = false
        npoplayer.uiComponents.adbutton?.hide()
        npoplayer.uiComponents.adlabel?.hide()
        npoplayer.uiManager?.release()
        npoplayer.createUIManager(playerapi, 'default')
        adUiSet = false
        removeListeners()
    }
    playerapi.on(PlayerEvent.AdBreakFinished, handleAdBreakFinished)

    /*
    * @function removeListeners
    * @description Removes the eventlisteners set in this file.
    * @returns {void}
    */
    function removeListeners() {
        playerapi.off(PlayerEvent.SourceLoaded, handleSourceLoaded)
        playerapi.off(PlayerEvent.Play, handlePlay)
        playerapi.off(PlayerEvent.AdBreakStarted, handleAdBreakStarted)
        playerapi.off(PlayerEvent.AdFinished, handleAdFinished)
        playerapi.off(PlayerEvent.AdBreakFinished, handleAdBreakFinished)
    }
}
