import { PlayerAPI, PlayerEvent, UIConfig } from 'bitmovin-player'
import { UIManager } from 'bitmovin-player-ui'
import { customSpecificErrorMessageOverlayConfig } from '../playeractions/handlers/customerrors'
import { localizationConfig } from '../utilities/localizationconfig'
import { nativeMobileUIContainer, sendCustomMessage } from './nativemobileuicontainer'
import { addFragments } from '../ui/components/nativemobile/addFragments'
import { CustomMessages, Section } from '../../types/interfaces'
import { processNicam } from './handlers/nicamhandler'
import { removeFragments } from '../fragments/removefragments'

// TODO: find explicit type for window
declare const window: any;

export function nativeMobileUiFactory (player: PlayerAPI, config: UIConfig = {}): UIManager {
    const uiConfig: UIConfig = {
        ...config,
        errorMessages: customSpecificErrorMessageOverlayConfig,
        disableAutoHideWhenHovered: true,
    }

    const mobileUIManager = new UIManager(
        player,
        nativeMobileUIContainer(player),
        uiConfig
    )

    UIManager.setLocalizationConfig(localizationConfig)

    let _streamObject: any = {}

    const removeFinishedClass = () => {
        const playerFinishedElement = document.querySelector('.bmpui-npo-player.bmpui-player-state-finished')
        
        if (playerFinishedElement) {
            playerFinishedElement.classList.remove('bmpui-player-state-finished')
        }
    }

    const clearUI = () => {
        // clear nicam icons
        const nicamElement = document.querySelector('#ui-container .bmpui-nicam')
        if (nicamElement) {
            nicamElement.innerHTML = '';
        }

        // clear live stream ui
        document.querySelector('.bmpui-npo-player')?.classList.remove('livestream-dvr')

        // remove title in thumbnail track
        const element = document.querySelector('.bmpui-npo-player .bmpui-seekbar-label-title')
        if (element) {
            element.textContent = ''
        }

        removeFragments(player, mobileUIManager)
    }

    const updateUI = () => {        
        // set nicam icons
        if (_streamObject.metadata?.ageRating) {
            processNicam(_streamObject, 'ui-container')
        }

        // set live stream ui
        const isLiveStream = _streamObject.stream?.isLiveStream && _streamObject.stream?.hasDvrWindow
        if (isLiveStream) {
            document.querySelector('.bmpui-npo-player')?.classList.add('livestream-dvr')
        }

        // add fragments
        if (_streamObject.segment) {
            const seg = _streamObject.segment
            const section: Section  = {
                start: seg.inpoint,
                end: seg.outpoint,
                title: _streamObject?.title || '',
            }

            addFragments(player, mobileUIManager, section)
        } else {
            // clear fragments: set to null
            addFragments(player, mobileUIManager, null)
        }

        // set title in thumbnail track
        const element = document.querySelector('.bmpui-npo-player .bmpui-seekbar-label-title')
        if (element) {
            element.textContent = _streamObject.title || ''
        }

        // remove segments / markers
        mobileUIManager.getTimelineMarkers().forEach(marker => {
            mobileUIManager.removeTimelineMarker(marker)
        })
    }

    if (window.bitmovin?.customMessageHandler) {
        window.bitmovin.customMessageHandler.on(CustomMessages.SET_STREAM_LINK, (data?: string) => {
            clearUI()
            if (data) {
                _streamObject = JSON.parse(data)
                updateUI()
            }
        })
    }

    player.on(PlayerEvent.Seeked, () => {
        removeFinishedClass()
    })

    player.off(PlayerEvent.Seeked, () => {
        removeFinishedClass()
    })

    player.on(PlayerEvent.Ready, () => {
        sendCustomMessage(CustomMessages.JAVASCRIPT_READY)
    })

    player.off(PlayerEvent.Ready, () => {
        sendCustomMessage(CustomMessages.JAVASCRIPT_READY)
    })

    return mobileUIManager
}