import { newStreamTracker } from '@npotag/tag'
import { bindPlayerEvents } from './eventbinding'
import { validatePrid } from '../../utilities/utilities'
import { AVType } from '@npotag/tag/dist/types/src/streamTracker'


/**
* @function startPlayerTracker
* @description This function creates a new StreamTracker instance and binds the player events to it.
* @param {Object} playerInstance - The NpoPlayer instance
* @param {number} duration - The duration of the stream in seconds
* @returns {void}
*/
export function startPlayerTracker (playerInstance: any, duration: number, version: string): void {
    if (playerInstance.npoTag?.pageTracker == null) {
        console.error('No pageTracker available')
    } else if (playerInstance.streamTracker == null) {

        const avTypeMapping: Record<string, AVType> = {
            'vod': 'video',
            'aod': 'audio'
        };

        const avType = avTypeMapping[playerInstance.streamObject.stream.avType];

        const streamTrackerConfig = {
            stream_length: duration,
            stream_id: validatePrid(playerInstance.streamObject.metadata.prid),
            player_id: 'npoplayer-web',
            av_type: avType,
            player_version: version,
            sko_player_version: '1.0.0'
        };
        const isLiveConfig = playerInstance.streamObject.stream.isLiveStream ? { isLive: true } : {};

        playerInstance.streamTracker = newStreamTracker(
            playerInstance.npoTag.pageTracker,
            streamTrackerConfig,
            isLiveConfig
        );

        bindPlayerEvents(playerInstance, playerInstance.player)
    }
}