import { PlayerAPI, PlayerEvent } from 'bitmovin-player'
import {
    ControlBar,
    Container,
    PlaybackTimeLabel,
    PlaybackTimeLabelMode,
    SeekBar,
    SeekBarLabel,
    type SettingsPanel,
    VolumeSlider,
    Spacer,
    SettingsToggleButton,
} from 'bitmovin-player-ui'
import { createForwardButton, createRewindButton } from '../buttons'
import { airPlaySupported } from '../../../utilities/utilities'
import {
    createAirPlayToggleButton,
    createCastToggleButton,
    createFullscreenToggleButton,
    createPictureInPictureToggleButton,
    createPlaybackToggleButton,
    createVolumeToggleButton,
} from './buttons'

export function createControlBar (
    player: PlayerAPI,
    settingsPanel: SettingsPanel,
) {
    const rewindButton = createRewindButton(player)
    const forwardButton = createForwardButton(player)

    const chromeCastButton = createCastToggleButton()

    chromeCastButton.hide()

    player.on(PlayerEvent.CastAvailable, () => {
        if(player.isCastAvailable()) chromeCastButton.show()
    })

    return new ControlBar({
        components: [
            settingsPanel,
            new Container({
                components: [
                    new PlaybackTimeLabel({
                        timeLabelMode: PlaybackTimeLabelMode.CurrentTime,
                        hideInLivePlayback: true,
                        cssClasses: ['current-time']
                    }),
                    new SeekBar({ label: new SeekBarLabel() }),
                    new PlaybackTimeLabel({
                        timeLabelMode: PlaybackTimeLabelMode.TotalTime,
                        cssClasses: ['total-time']
                    })
                ],
                cssClasses: ['controlbar-top']
            }),
            new Container({
                components: [
                    createPlaybackToggleButton(),
                    createVolumeToggleButton(),
                    new VolumeSlider(),
                    rewindButton,
                    forwardButton,
                    new Spacer(),
                    ...(document.pictureInPictureEnabled && /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? [createPictureInPictureToggleButton()] : []),
                    ...(airPlaySupported() ? [createAirPlayToggleButton()] : []),
                    chromeCastButton,
                    // createSettingsButton(settingsPanel),
                    new SettingsToggleButton({ settingsPanel }),
                    createFullscreenToggleButton()
                ],
                cssClasses: ['controlbar-bottom']
            })
        ]
    })
}
