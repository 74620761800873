
import { type StreamTracker, type NPOTag } from '@npotag/tag'
import { type InitialisationProps } from '@npotag/tag/dist/types/src/npoTag'
import {
    HttpResponseType,
    Player,
    PlayerEvent,
    type PlayerAPI,
    type PlayerConfig,
    type SourceConfig,
    type Technology,    
    type UIConfig,
} from 'bitmovin-player'
import AdsModuleBM from 'bitmovin-player/modules/bitmovinplayer-advertising-bitmovin'
import { logEvent, initPlayerTracker, startPlayerTracker } from './js/tracking/playertracker'
import { hidePlayNextScreen, showPlayNextScreen } from './js/ui/components/shared/playnextscreen'
import { validateStreamLength, getModuleExport } from './js/utilities/utilities'
import { verifyDRM } from './js/drm/verifydrm'
import { setFragments } from './js/fragments/setfragments'
import * as playerAction from './js/playeractions/playeractions'
import { getStreamObject } from './js/api/getstreamobject'
import { printVersion } from './js/utilities/printversion'
import { UIManager } from 'bitmovin-player-ui'
import { customSpecificErrorMessageOverlayConfig } from './js/playeractions/handlers/customerrors'
import { createUIContainer } from './js/ui/uicontainer'
import { localizationConfig } from './js/utilities/localizationconfig'
import { processNicam } from './js/ui/handlers/nicamhandler'
import { npoCdnProviders } from './js/cdnproviders'
import { LogEmitter } from './types/classes'
import { handlePreRolls } from './js/ads/ster'
import pkg from '../package.json';
import {
    type DRMProfile,
    type ApiPayload,
    type Fragments,
    type NPOTagObject,
    type Profile,
    type Section,
    type StreamObject,
    type StreamOptions,
    type UIComponents
} from './types/interfaces'
import { nativeMobileUiFactory } from './js/ui/nativemobileuifactory'
export default class NpoPlayer {
    playerConfig: PlayerConfig
    sourceConfig: SourceConfig
    streamObject: StreamObject
    player: PlayerAPI | null
    uiManager: UIManager | null
    npoTag: NPOTagObject | undefined
    streamTracker: StreamTracker | null
    logEmitter: LogEmitter
    uiComponents: UIComponents
    fragment: Section | null
    container: HTMLElement
    streamOptions: StreamOptions
    jwt: string
    apiPayload: ApiPayload
    adBreakActive: boolean
    version: string
    drmProfile: DRMProfile

    constructor (
        _container: HTMLElement,
        _playerConfig: PlayerConfig,
        _npotag?: InitialisationProps | null,
        _npotaginstance?: NPOTag
    ) {
        this.playerConfig = _playerConfig 
        this.sourceConfig = {}
        this.streamObject = {
            stream: { drmType: '', streamProfile: '', streamURL: '', avType: '' },
            metadata: { title: '', description: '' },
            assets: { scrubbingThumbnail: '', subtitles: [] }
        }
        this.container = _container
        this.player = null
        this.uiManager = null
        this.fragment = null
        this.streamTracker = null
        this.streamOptions = {}
        this.jwt = ''
        this.apiPayload = { baseURL: '', jwt: '', data: {} }
        this.adBreakActive = false
        this.version = pkg.version
        this.drmProfile = { profileName: '', drm: ''}

        initPlayerTracker(this, _npotag, _npotaginstance)

        this.logEmitter = new LogEmitter()
        this.uiComponents = {}

        this.initPlayer(this.container, _playerConfig)
    }

    initPlayer (_container: HTMLElement, playerConfig: PlayerConfig): void {
        // Upon initiation print player version in console
        printVersion(this.version)

        // Process player config to add conditional properties depending on initialization options
        const processedPlayerConfig = playerAction.processPlayerConfig(this, playerConfig)

        //add the bitmovin ads module for more flexibility in ad handling
        Player.addModule(getModuleExport(AdsModuleBM));

        this.player = new Player(_container, processedPlayerConfig)

        this.createUIManager(this.player, 'default')

        this.container.addEventListener(
            'keydown',
            e => {
                this.keyPress(e)
            },
            true
        )
    }

    async loadStream (_jwt: string, options: StreamOptions = {}) {
        if (this.player == null) {
            console.error('Er is nog geen player geladen.')
            return
        }
        if (this.adBreakActive) {
            console.error('Je kunt geen nieuwe content laden tijdens een reclameblok.')
            return
        }

        // Set jwt on class to make it accesible for DRM fallback logic
        this.jwt = _jwt

        //StreamAPI logic
        this.drmProfile = await this.decideProfile(options?.preferredDRM ?? '')
        const defaultEndpoint = 'https://prod.npoplayer.nl/'
        const endpoint = options?.endpoint ?? defaultEndpoint
        const payload: ApiPayload = {
            baseURL: endpoint,
            jwt: _jwt,
            data: { profileName: this.drmProfile.profileName, drmType: this.drmProfile.drm , referrerUrl: window.location.href}
        }

        
        let _streamObject

        try {
            _streamObject = await getStreamObject(this, payload)
            this.streamObject = _streamObject
        } catch (err: any) {
            this.doError('Het is niet gelukt de stream op te halen. \n' + err)
            this.player.pause()
            return
        }

        //if the streamobject is undefined abort the function early
        if (this.streamObject?.stream == undefined) return

        // Use data from options and/or streamobject response to make a source config
        const drmType = this.streamObject.stream.drmType ?? null;
        this.sourceConfig = this.processSourceConfig(
            options.sourceConfig ?? {},
            this.streamObject,
            drmType && drmType.length > 0 ? this.drmProfile.drm : null
        );

        //add drm and jwt info to metadata to supply the chromecast receiver with the correct info
        if (this.sourceConfig?.metadata) {
            this.sourceConfig.metadata.streamProfile = this.drmProfile.profileName
            this.sourceConfig.metadata.drmType = this.drmProfile.drm
            this.sourceConfig.metadata.jwt = _jwt
            this.sourceConfig.metadata.av_type = this.streamObject.stream.avType
        }

        // verify that DRM is not yet expired
        await verifyDRM(this, this.player, payload)

        // check if live stream without DVR
        const isLiveStream = this.streamObject.stream.isLiveStream && this.streamObject.stream.hasDvrWindow
        if (isLiveStream) {
            document.querySelector(`#${this.container.id} .bmpui-npo-player`)?.classList.add('livestream-dvr')
        }
        
        // set nicam kijkwijzer icons
        processNicam(this.streamObject, this.container.id)

        const isAutoplayEnabled = this.player.getConfig()?.playback?.autoplay ?? false;

        if (isAutoplayEnabled && this.streamObject.stream.streamProfile === 'progressive') {
            this.player.on(PlayerEvent.SourceLoaded, () => {
                this.player?.play();
            });
        }

        // Settings buttons based on response
        const checkSubtitles = () => {
            if (!this.player) return
            const subtitles = this.player.subtitles?.list()
            if (subtitles != null && subtitles.length > 0 && this.uiComponents.subtitlesButton) {
                this.uiComponents.subtitlesButton.show()
            } else if(this.uiComponents.subtitlesButton) this.uiComponents.subtitlesButton.hide()
        }

        this.player.on(PlayerEvent.Ready, checkSubtitles)
        this.player.off(PlayerEvent.Ready, checkSubtitles)

        if (this.player.getAvailableVideoQualities().length > 0 && this.uiComponents.qualityButton) {
            this.uiComponents.qualityButton.show()
        }

        // Start NPO Tag PlayerTracker
        startPlayerTracker(this, validateStreamLength(this.streamObject.metadata.duration), this.version)
        logEvent(this, 'load')

        const addFragments = (section: Section) => {
            void this.setFragments({ sections: [section] })
        }

        if (_streamObject.segment != null) {
            const seg = _streamObject.segment
            const section: Section = {
                start: seg.inpoint,
                end: seg.outpoint,
                title: this.sourceConfig.title || '',
            }
            this.player.off(PlayerEvent.Ready, () => addFragments(section))
            this.player.on(PlayerEvent.Ready, () => addFragments(section))
        }

        // Timeshift to offset if offset is present in streamoptions
        if (options?.startOffset != null) playerAction.handleStartOffset(this.player, options.startOffset)

        // Set listeners for live offset and timestamps
        const setLiveOffsetListener = function (this: NpoPlayer) {
            if (this.player === null) return

            this.player.off(PlayerEvent.SourceLoaded, setLiveOffsetListener)
            playerAction.handleLiveOffsetLogic(this, this.player, options)
        }.bind(this)
        this.player.on(PlayerEvent.SourceLoaded, setLiveOffsetListener)

        // Load fragments through options
        if (options?.fragments != null) {
            const fragments = options.fragments
            this.player.off(PlayerEvent.Ready, () => { void this.setFragments(fragments) })
            this.player.on(PlayerEvent.Ready, () => { void this.setFragments(fragments) })
        }

        // Set title in seekbar thumbnail
        if (_streamObject.segment === undefined && options?.fragments === undefined) {
            const element = document.querySelector('.bmpui-npo-player .bmpui-seekbar-label-title')
            if (element !== null) {
                element.textContent = this.sourceConfig.title || ''
            }
        }

        // Remove finished class when you start seeking again
        this.player.on(PlayerEvent.Seeked, () => {
            document.querySelector('.bmpui-npo-player.bmpui-player-state-finished')?.classList.remove('bmpui-player-state-finished')
        })

        // If the stream options contain a PRID for the next episode show the playnext screen once playback is finished
        // Test this behaviour by uncommenting the following line, mind that the api does not support this feature yet:
        // options.prid = 'POW_04922615'
        this.player.off(PlayerEvent.PlaybackFinished, () => {
            this.showPlayNextScreen()
        })
        if (options?.prid != null) {
            this.player.on(PlayerEvent.PlaybackFinished, () => {
                this.showPlayNextScreen()
            })
        }

        handlePreRolls(this.player, this.streamObject, this)
    }

    async createUIManager(player: PlayerAPI, variant: string) {
        const uiConfig: UIConfig = {
            errorMessages: customSpecificErrorMessageOverlayConfig,
            disableAutoHideWhenHovered: true
        }
        this.uiManager = new UIManager(
            player,
            createUIContainer(this, player, variant),
            uiConfig
        )
        UIManager.setLocalizationConfig(localizationConfig)
    }

    async setFragments (fragments: Fragments) {
        if (this.player && this.uiManager) {
            void setFragments(this.player, this.uiManager, fragments)
        }
    }

    async decideProfile (preferredDRM = ''): Promise<Profile> {
        let bestWithDRM: string = ''
        let bestWithoutDRM: string = ''
        let bestDRM: string = ''
        let supportedTech: Technology[]
        let supportedDRM: string[]
        if (this.player == null) {
            console.log('No player detected')
            return { profileName: '', drm: '' }
        } else {
            supportedTech = this.player.getSupportedTech()
            supportedDRM = await this.player.getSupportedDRM()
        }

        supportedTech.forEach(function (tech) {
            if (bestWithoutDRM === '') bestWithoutDRM = tech.streaming
            if (bestWithDRM !== '') return

            // DASH
            if (tech.streaming === 'dash') {
                supportedDRM.forEach(function (drm) {
                    if (
                        bestDRM !== '' &&
                        (preferredDRM === '' || preferredDRM.length > 0)
                    ) { return }
                    if (['com.widevine.alpha'].includes(drm)) { bestDRM = 'widevine' }
                    if (
                        [
                            'com.microsoft.playready',
                            'com.microsoft.playready.recommendation'
                        ].includes(drm)
                    ) { bestDRM = 'playready' }
                })
                if (bestDRM !== '') bestWithDRM = 'dash'
            }

            // HLS
            if (tech.streaming === 'hls') {
                if (
                    bestDRM !== '' &&
                    (preferredDRM === '' || bestDRM === preferredDRM)
                ) { return }
                supportedDRM.forEach(function (drm) {
                    if (
                        ['com.apple.fps.1_0', 'com.apple.fps.2_0'].includes(drm)
                    ) { bestDRM = 'fairplay' }
                })
                if (bestDRM !== '') bestWithDRM = 'hls'
            }
        })

        if (bestWithDRM !== '')return { profileName:bestWithDRM, drm: bestDRM }
        return { profileName:bestWithoutDRM, drm: bestDRM }
    }

    processSourceConfig (
        _sourceConfig: SourceConfig = {},
        _streamObject: StreamObject,
        drm: string | null = null
    ): SourceConfig {
        const sourceConfig: SourceConfig = {}

        sourceConfig.title =
            _sourceConfig.title ?? _streamObject.metadata.title ?? ''
        sourceConfig.description =
            _sourceConfig.description ??
            _streamObject.metadata.description ??
            ''
        sourceConfig.poster =
            _sourceConfig.poster ?? _streamObject.metadata.poster ?? ''

        sourceConfig.metadata = {
            title: sourceConfig.title,
            description: sourceConfig.description,
            poster: sourceConfig.poster
        }

        // strict-boolean-expressions is disabled here because I did not write this code and
        // I do not know if _sourceConfig.subtitleTracks and _streamObject.assets.subtitles can both be null at once
        sourceConfig.subtitleTracks =
            _sourceConfig.subtitleTracks ??
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            (_streamObject.assets.subtitles
                ? _streamObject.assets.subtitles.map((subtitle, i) => ({
                    url: subtitle.location,
                    lang: subtitle.iso,
                    label: subtitle.name,
                    kind: 'subtitle',
                    id: 'sub' + i
                }))
                : undefined)

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        sourceConfig.thumbnailTrack =
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            _sourceConfig.thumbnailTrack ??
                _streamObject.assets.scrubbingThumbnail
                ? { url: _streamObject.assets.scrubbingThumbnail }
                : undefined

        sourceConfig.options = _sourceConfig.options // Nog nodig?

        // Stream type
        const streamProfile = _streamObject.stream.streamProfile;
        const streamURL = _streamObject.stream.streamURL;

        const hlsConfig = _sourceConfig.hls === '' ? undefined : _sourceConfig.hls;
        const dashConfig = _sourceConfig.dash === '' ? undefined : _sourceConfig.dash;
        const progressiveConfig = _sourceConfig.progressive === '' ? undefined : _sourceConfig.progressive;

        if (streamProfile === 'hls') {
            sourceConfig.hls = hlsConfig ?? streamURL ?? '';
            sourceConfig.dash = '';
            sourceConfig.progressive = '';
        } else if (streamProfile === 'dash') {
            sourceConfig.hls = '';
            sourceConfig.dash = dashConfig ?? streamURL ?? '';
            sourceConfig.progressive = '';
        } else if (streamProfile === 'progressive') {
            sourceConfig.hls = '';
            sourceConfig.dash = '';
            sourceConfig.progressive = progressiveConfig ?? streamURL ?? '';
        }

        // DRM
        const npoDrmGateway = 'https://npo-drm-gateway.samgcloud.nepworldwide.nl/authentication?custom_data='

        if (drm === 'fairplay') {
            sourceConfig.drm = {
                fairplay: {
                    certificateURL:
                        'https://fairplay.npo.nl/certificate/fairplay.cer', // "https://s3-eu-west-1.amazonaws.com/24i-npo-stream/fairplay.cer"
                    LA_URL:
                        npoDrmGateway + _streamObject.stream.drmToken,
                    prepareMessage: event => {
                        return new Uint8Array(event.message)
                    },
                    prepareContentId: url => {
                        const link = document.createElement('a')
                        const trimmedUrl = url
                            .split('')
                            .filter(char => !(char.codePointAt(0) == null))
                            .join('')
                        link.href = trimmedUrl.replace('T', '')
                        return link.hostname
                    },
                    prepareLicense: license => {
                        let binary = ''
                        const bytes = new Uint8Array(license)
                        const len = bytes.byteLength
                        for (let i = 0; i < len; i++) {
                            binary += String.fromCharCode(bytes[i])
                        }
                        return window.btoa(binary)
                    },
                    licenseResponseType: HttpResponseType.ARRAYBUFFER,
                    useUint16InitData: true
                }
            }
        }
        if (drm === 'widevine') {
            sourceConfig.drm = {
                widevine: {
                    LA_URL:
                        npoDrmGateway + _streamObject.stream.drmToken,
                    audioRobustness: 'SW_SECURE_CRYPTO',
                    videoRobustness: 'SW_SECURE_CRYPTO'
                }
            }
        }
        if (drm === 'playready') {
            sourceConfig.drm = {
                playready: {
                    LA_URL:
                        npoDrmGateway + _streamObject.stream.drmToken
                }
            }
        }

        // CDN
        const streamSource = _streamObject.stream.streamURL
        let cdnString = streamSource
        npoCdnProviders.forEach(function (value, key) {
            if (streamSource.includes(key)) {
                cdnString = value
            }
        })

        if (sourceConfig.analytics == null) sourceConfig.analytics = {}

        sourceConfig.analytics.cdnProvider = cdnString
        sourceConfig.analytics.videoId = _streamObject.metadata.prid
        sourceConfig.analytics.title = _streamObject.metadata.title

        sourceConfig.analytics = {
            ...sourceConfig.analytics,
            ..._sourceConfig.analytics
        }

        // Labeling subtitles and qualities
        const getSubtitleLabels = function (data: any): string {
            if (data.label === 'nl') {
                return 'Nederlands'
            }
            return data.label
        }
        const getQualityLabels = function (data: any): string {
            return data.height + 'p'
        }

        if(sourceConfig.progressive !== '') {
            sourceConfig.labeling = {
                dash: {
                    qualities: getQualityLabels,
                    subtitles: getSubtitleLabels
                },
                hls: {
                    qualities: getQualityLabels,
                    subtitles: getSubtitleLabels
                }
            }
        }

        return sourceConfig
    }

    doError (input: any, status?: number) {
        if(this.player == null) return

        if (status) {
            this.logEmitter.emit('logError', status)
        }

        playerAction.handlePlayerError(this.player, this.uiComponents, input)
    }

    // Hotkeys
    keyPress (e: KeyboardEvent) {
        if (['Space', 'ArrowUp', 'ArrowDown'].includes(e.code)) e.preventDefault();
        
        if (!this.player) return;

        playerAction.resolveKeyPress(this.player, this, e)
    }

    setVolume (volume: number): void {
        if (this.player == null) return
        this.player.setVolume(volume)
    }

    increaseVolume (): void {
        if (this.player == null) return
        this.setVolume(this.player.getVolume() + 10)
    }

    decreaseVolume (): void {
        if (this.player == null) return
        this.setVolume(this.player.getVolume() - 10)
    }

    goForward (seconds: number): void {
        if (this.player == null) return
        if (this.player.isLive() ?? false) {
            this.player.timeShift(
                Math.min(0, this.player.getTimeShift() + seconds)
            )
        } else {
            this.player.seek(this.player.getCurrentTime() + seconds)
        }
    }

    goBackwards (seconds: number) {
        if (this.player == null) return
        if (this.player.isLive() ?? false) {
            this.player.timeShift(this.player.getTimeShift() - seconds)
        } else {
            this.player.seek(this.player.getCurrentTime() - seconds)
        }
    }

    watchFromStart (): void {
        if (this.player == null) return
        playerAction.shiftToProgramStart(this.player, this.streamOptions.liveProgramTime)
    }

    // Show the "next episode is playing soon..." screen if a PRID is supplied in player options
    showPlayNextScreen (): void {
        showPlayNextScreen(this.doPlayNext.bind(this))
    }

    hidePlayNextScreen (): void {
        hidePlayNextScreen()
    }

    doPlayNext (): void {
        this.hidePlayNextScreen()
        void this.loadStream(this.jwt, this.streamOptions)
    }

    destroy () {
        try {
            if (this.npoTag != null) {
                clearInterval(this.npoTag.heartbeatInterval)
            }
            this.uiManager?.release()
            void this.player?.destroy()
            return true
        } catch (e) {
            console.log('NPO Player is al destroyed', e)
            return false
        }
    }

    unload () {
        try {
            if (this.npoTag != null) {
                clearInterval(this.npoTag.heartbeatInterval)
            }
            void this.player?.unload()
            return true
        } catch (e) {
            console.log('NPO Player is al unloaded', e)
            return false
        }
    }

    isFairPlayDrmSupported (ks?: any) {
        let isSupported = false;
        ['com.apple.fps.1_0', 'com.apple.fps.2_0'].forEach(ks => {
            if (
                typeof (window as any).WebKitMediaKeys === 'function' &&
                Boolean(
                    (window as any).WebKitMediaKeys?.isTypeSupported(
                        ks,
                        'video/mp4'
                    )
                )
            ) {
                isSupported = true
            }
        })
        return isSupported
    }
}

// overwrite default bitmovin function in native mobile to load our own UI
(window as any).bitmovin.playerui = function() {};

(window as any).bitmovin.playerui.UIFactory = {
    buildDefaultSmallScreenUI: function (player: PlayerAPI, config: UIConfig = {}): UIManager {
        return nativeMobileUiFactory(player, config)
    }
}