import { PlayerAPI, PlayerEvent } from 'bitmovin-player'
import { Section, type Fragments } from '../../types/interfaces'
import { UIManager } from 'bitmovin-player-ui'
import { removeFragments } from './removefragments'

let fragment: Section | null  = null
let playerInstance: PlayerAPI | null = null

const setTitle = () => {
    // set title in thumbnail track
    const element = document.querySelector('.bmpui-npo-player .bmpui-seekbar-label-title')

    if (element !== null && element.textContent === '') {
        element.textContent = fragment?.title || ''
    }
}

export const checkFunction = () => (e: any) => {
    if (!fragment || !playerInstance) return

    if (e.time > fragment.end) {
        playerInstance.seek(Math.max(fragment.start, fragment.end))
        playerInstance.pause()
    } else if (e.time < fragment.start) {
        playerInstance.seek(fragment.start)
    }

    setTitle()
}

export const seekFunction = () => (e: any) => {
    if (!fragment || !playerInstance) return

    // web UI and JS bridge have different types
    const seekTarget = e.seekTarget ? e.seekTarget : e.to.time

    if (seekTarget > fragment.end || seekTarget < fragment.start) {
        playerInstance.seek(
            Math.max(
                fragment.start,
                Math.min(fragment.end, seekTarget)
            )
        )
    }

    setTitle()
}

export async function setFragments (player: PlayerAPI, uiManager: UIManager, fragments: Fragments) {
    fragment = fragments.sections[0] || null
    playerInstance = player

    const unplayableClass = 'seekbar-marker-unplayable'

    const playFragmentFromStart = () => {
        if (!fragment) return

        player.seek(fragment.start)
        if (player.isPaused()) void player.play()
    }

    const addMarkers = () => {
        if (!fragment) return

        const timelineMarkers = [
            {
                time: 0,
                duration: fragment.start,
                title: 'Niet beschikbaar',
                cssClasses: [unplayableClass]
            },
            {
                time: fragment.end,
                duration: 10000000,
                title: 'Niet beschikbaar',
                cssClasses: [unplayableClass]
            },
        ];
    
        timelineMarkers.forEach(function(marker) {
            uiManager.addTimelineMarker(marker);
        })

        playFragmentFromStart()

        player.off(PlayerEvent.TimeChanged, checkFunction())
        player.off(PlayerEvent.Seek, seekFunction())
        player.on(PlayerEvent.TimeChanged, checkFunction())
        player.on(PlayerEvent.Seek, seekFunction())

    }

    removeFragments(player, uiManager)
    addMarkers()
}