import { Label, TitleBar } from 'bitmovin-player-ui'
import type NpoPlayer from '../../../npoplayer'

export function createTitleBar (npoplayer: NpoPlayer) {
    const nicam = new Label({ text: '', cssClass: 'nicam' })
    const titleBar = new TitleBar({ cssClasses: ['metadata'] })
    titleBar.addComponent(nicam)
    npoplayer.uiComponents.nicam = nicam

    return titleBar
}
