import {
    BufferingOverlay,
    CastStatusOverlay,
    ErrorMessageOverlay,
    PlaybackToggleOverlay,
    SubtitleOverlay,
    UIContainer,
} from 'bitmovin-player-ui';
import { PlayerAPI } from 'bitmovin-player';

import { createPlayNextScreen } from './components/nativemobile/playnext'
import { createCTABar } from './components/nativemobile/ctabar'
import { createControlBar } from './components/nativemobile/controlbar'

import { createTopBar } from './components/nativemobile/topbar'
import { createSettingsPanel } from './components/settingspanel'

import { createMiddleButtons } from './components/buttons'
import { CustomMessageHandlerInterface, CustomMessages } from '../../types/interfaces';
import { createTitleBar } from './components/nativemobile/titlebar';

// TODO: find explicit type for window
declare const window: any;

export function sendCustomMessage(message: CustomMessages, data?: CustomMessageHandlerInterface) {
    try {
        if (window.bitmovin.customMessageHandler) {
            return JSON.parse(window.bitmovin.customMessageHandler.sendSynchronous(message, data !== undefined ? JSON.stringify(data) : '{}'))
        }
    } catch {
        // do nothing
    }
}

export function nativeMobileUIContainer (player: PlayerAPI) {
    const middleButtons = createMiddleButtons(player)
    const playNextScreen = createPlayNextScreen()
    const settingsPanel = createSettingsPanel(player)
    const ctaBar = createCTABar(player)
    const topBar = createTopBar(player, settingsPanel)
    const controlBar = createControlBar(player, settingsPanel)
    const errorMessageOverlay = new ErrorMessageOverlay()

    return new UIContainer({
        components: [
            new SubtitleOverlay(),
            new BufferingOverlay(),
            new PlaybackToggleOverlay(),
            new CastStatusOverlay(),
            playNextScreen,
            middleButtons,
            controlBar,
            ctaBar,
            topBar,
            createTitleBar(),
            errorMessageOverlay
        ],
        cssClasses: ['npo-player', 'native-mobile'],
    });
}