/*
* This file handles the processing of NICAM and Agerating metadata to display the relevant Kijkwijzer icons on the player
*/

import { StreamObject } from 'types/interfaces'

/*
* This function processes the ageRating and nicam metadata and adds the relevant icons to the player
* @function processNicam
* @param {StreamObject} streamObject - The stream object
* @param {string} containerId - The id of the player container element (this is used to avoid conflicts with multiple players on one page)
* @returns {void}
*/
export function processNicam(streamObject: StreamObject, containerId: string) {
    const nicamElement = document.querySelector(`#${containerId} .bmpui-nicam`)
    if (nicamElement) {
        nicamElement.innerHTML = ''
        streamObject.metadata.ageRating && addNicamIcon(streamObject.metadata.ageRating, nicamElement)
        streamObject.metadata.nicam?.forEach((character: string) => addNicamIcon(character, nicamElement))
    }
}

/*
* This function adds the relevant Kijkwijzer icon to the player
* @function addNicamIcon
* @param {string} character - The character to add the icon for
* @param {Element} nicamElement - The element to add the icon to
* @returns {void}
*/
function addNicamIcon(character: string, nicamElement: Element) {
    const iconClass = `nicam-icon-${character.toLowerCase()}`
    const span = document.createElement('span')
    span.classList.add('nicam-icon', iconClass)
    nicamElement.appendChild(span)
}

