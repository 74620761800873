export const npoCdnProviders = new Map()
npoCdnProviders.set('nep.global.ssl.fastly.net', 'NEP')
npoCdnProviders.set('cdn.streamgate.nl', 'NEP')
npoCdnProviders.set('npo-fsly.cdn.streamgate.io', 'FASTLY')
npoCdnProviders.set('cdn.eurovisioncdn.net', 'EUROVISION')
npoCdnProviders.set('npo.prd.cdn.bcms.kpn.com', 'KPN')
npoCdnProviders.set('cdn.bcms.kpn.com', 'KPN')
npoCdnProviders.set('Akamai', 'AKEMAI')
npoCdnProviders.set('icecast.omroep.nl', 'NPO')
npoCdnProviders.set('content.omroep.nl', 'NPO')
npoCdnProviders.set('podcast.npo.nl', 'NPO')
npoCdnProviders.set('video.omroep.nl', 'NPO')
