// printVersion.js

export function printVersion (version: string) {
    const asciiArt = `
                   ╔▒▒╖                        
                _╔╠╠╠╠╠╠╠φ_                    
              _#╠╠╠╠╠╠╠╠╠╠╠#_                  
            ╓╔╠╠╠╠╝╝╠╠╠╠╠╠╠╠╠▒,                
          ╔╬╠╠╠╠╠    '╚╠╠╠╠╠╠╠╠▒╖              
        ╔╬╠╠╠╠╠╠╠       '╚╠╠╠╠╠╠╠╬╦            
     _╓╠╠╠╠╠╠╠╠╠╠          '╚╠╠╠╠╠╠╠╖╖         
     ╠╠╠╠╠╠╠╠╠╠╠╠            ╠╠╠╠╠╠╠╠▒         
     ╬╠╠╠╠╠╠╠╠╠╠╠          _╔╬╠╠╠╠╠╠╠╝         
      \`╝╠╠╠╠╠╠╠╠╠       _╔╠╠╠╠╠╠╠╠╠╩\`          
        \`╚╠╠╠╠╠╠╠    _╔╬╠╠╠╠╠╠╠╠╠╩             
           ╙╠╠╠╠╠▒╗φ╬╠╠╠╠╠╠╠╠╠╠╜               
             ╙╬╠╠╠╠╠╠╠╠╠╠╠╠╠╠╙                 
               '╬╠╠╠╠╠╠╠╠╠╝^                   
                 \`╝╠╠╠╠╠╩\`                     
                   \`╙╙╙                        
`

    const title = 'NPO Player'
    const versionString = 'v' + String(version)

    const colorCode = '#ff6d00'
    const titleStyle = `font-size: 24px; font-weight: bold; color: ${colorCode}; padding: 2px 4px;`
    const versionStyle = 'font-size: 24px; font-weight: bold; padding: 2px 4px;'

    console.log(`%c${asciiArt}%c${title} %c${versionString}`, `color: ${colorCode}; background-color: rgb(11, 28, 54);`, titleStyle, versionStyle)
}
