export function showPlayNextScreen (playNextFunction: () => void): void {
    const playnextOverlay = document.querySelector(
        '.bmpui-overlay-playnext'
    )
    document
        .querySelector(
            '.bmpui-overlay-playnext .bmpui-container-wrapper .bmpui-ui-hugeplaynextbacktogglebutton'
        )
        ?.insertAdjacentHTML(
            'beforeend',
            '<svg class="playnextcountdown" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" class="ui-circular-chart"><path shape-rendering="geometricPrecision" class="ui-circle-bg" fill="none" stroke="white" stroke-width="2" opacity="50%" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" /><path shape-rendering="geometricPrecision" class="ui-circle" fill="none" stroke="white" stroke-width="2" stroke-dasharray="100, 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" /></svg> '
        )
    playnextOverlay?.classList.add('show')
    window.setTimeout(() => {
        // check classlist to see if the overlay is not already cleared
        if (playnextOverlay?.classList.contains('show') ?? false) {
            playNextFunction();
        }
    }, 15000)
}

export function hidePlayNextScreen (): void {
    document.querySelector('.playnextcountdown')?.remove()
    document.querySelector('.bmpui-overlay-playnext')?.classList.remove('show')
}