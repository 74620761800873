/*
* ctabar.ts
* This file contains the code for the ctabar component used to display situational "call to action" buttons in the player.
* These buttons include:
* - Watch from start button (only used in live streams)
* - Go back live button (not used in the current implementation)
* - Ad button
* - Accomodating Ad label
*/

import { Button, Container, Label } from 'bitmovin-player-ui'
import {
    createGoBackLiveButton,
    createWatchFromStartButton
} from './buttons'
import { createAdButton } from './adbutton'
import { createAdLabel } from './adlabel'
import type NpoPlayer from '../../../npoplayer'
import { type PlayerAPI } from 'bitmovin-player'
import { ButtonConfig } from 'bitmovin-player-ui/dist/js/framework/components/button'
import { LabelConfig } from 'bitmovin-player-ui/dist/js/framework/components/label'

/*
* @function createCTABar
* @description This function creates the ctabar component and assembles the buttons in the correct order.
* @param {PlayerAPI} player - The player instance
* @param {NpoPlayer} npoplayer - The player instance
* @param {string} variant - The variant of the player
* @returns {Container} - The ctabar component
*/
export function createCTABar (player: PlayerAPI, npoplayer: NpoPlayer, variant: string) {
    const goBackLiveButton = createGoBackLiveButton(player)
    const watchFromStartButton = createWatchFromStartButton(player, npoplayer)

    let barComponents: (Button<ButtonConfig> | Label<LabelConfig>)[] = [
        watchFromStartButton,
        goBackLiveButton
    ]

    if (variant === 'ad') {
        const adLabel = createAdLabel(npoplayer)
        const adButton = createAdButton(npoplayer)
        barComponents.push(adLabel)
        barComponents.push(adButton)
        adLabel.hide()
    }

    return new Container({
        components: barComponents,
        cssClasses: ['controlbar-textbuttons']
    })
}
