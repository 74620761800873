import { noTrailingSlash } from '../utilities/utilities'
import { type ApiPayload } from '../../types/interfaces'
import type NpoPlayer from '../../npoplayer'

export async function getStreamObject (npoplayer: NpoPlayer, payload: ApiPayload) {
    const response = await fetch(
        noTrailingSlash(payload.baseURL) + '/stream-link',
        {
            method: 'post',
            headers: {
                Accept: '*/*',
                Authorization: payload.jwt,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload.data)
        }
    )

    const streamApiResponse = await response.json()

    if (!response.ok) {
        npoplayer.doError(`Het is niet gelukt de stream op te halen: \n ${streamApiResponse.body}`, streamApiResponse.status)
    }

    return streamApiResponse
}
