/*
* This file contains the code for the 'Meer' button linking to the advertiser's website that appears when an ad is playing.
* The component is added to the uiComponents object of the player instance so it can be populated with the relevant ad URL.
*/

import { Button} from 'bitmovin-player-ui'
import type NpoPlayer from '../../../npoplayer'

/*
* @function createAdButton
* @param {NpoPlayer} npoplayer - The player instance
* @returns {Button} - The ad button
*/
export function createAdButton (npoplayer: NpoPlayer) {
    npoplayer.uiComponents.adbutton = undefined
    const adButton = new Button({
        cssClass: 'ui-textbutton ui-sterbutton bmpui-ui-button',
        text: 'Meer info',
        hidden: true
    })
    
    npoplayer.uiComponents.adbutton = adButton
    return adButton
}