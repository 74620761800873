/*
 * This file contains small utility functions that have no relation to the broader player logic
 */

import {
    i18n,
    type LocalizableText
} from 'bitmovin-player-ui/dist/js/framework/localization/i18n'

/**
 * @function jwtToBase64
 * @description Converts a JWT string to a base64 encoded string.
 * @param {string} jwt - The JWT string to convert.
 * @returns {string} The converted base64 string.
 */
export const jwtToBase64 = (jwt: string) => {
    return jwt.toString().split('.')[1].replace('-', '+').replace('_', '/')
}

/**
 * @function base64ToObject
 * @description Converts a base64 encoded JWT string to a JSON object.
 * @param {string} base64 - The base64 string to convert.
 * @returns {Object} The converted JSON object.
 */
export const base64ToObject = (base64: string) => {
    return JSON.parse(
        decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return (
                        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    )
                })
                .join('')
        )
    )
}

/**
 * @function noTrailingSlash
 * @description Removes the trailing slash from the provided url if it exists.
 * @param {string} url - The url to remove the trailing slash from.
 * @returns {string} The url without the trailing slash.
 */
export const noTrailingSlash = (url: string) => {
    return url.slice(-1) === '/' ? (url = url.slice(0, -1)) : url
}

/**
 * @function localize
 * @description Returns the localized text for the provided key.
 * @param {key} key - The key of the text to localize.
 * @returns {LocalizableText} The localized text.
 */
export function localize (key: string): LocalizableText {
    return i18n.getLocalizer(key)
}

/**
 * @function validateStreamLength
 * @description Validates the provided stream duration and converts it to seconds. 
 * If the duration is null, returns -1.
 * @param {string|number} duration - The stream duration to validate, can be either a string or a number.
 * @returns {number} The duration in seconds or -1 if the duration is null.
 */
export function validateStreamLength (duration: string | number | undefined): number {
    return duration !== null ? msToSeconds(Number(duration)) : -1
}

/**
 * @function msToSeconds
 * @description Converts milliseconds to seconds.
 * @param {number} ms - The milliseconds to convert.
 * @returns {number} The converted milliseconds.
 */
export function msToSeconds (ms: number): number {
    return ms / 1000
}

/**
* @function validatePrid
* @description Validates the provided prid. If the prid is null or empty, returns 'unknown'.
* @param {string|null} prid - The prid to validate.
* @returns {string} The validated prid or 'unknown' if the prid is null or empty.
*/
export function validatePrid (prid: string | null): string {
    return prid !== '' && prid !== null ? prid : 'unknown'
}

/**
 * @function getModuleExport
 * @description Returns the default export of a module if it exists, otherwise returns the module itself.
 * This is used to support both ES6 and CommonJS modules.
 * @param {unkown} module - The module to get the export from.
 * @returns {unknown} module - The multicompatible module export.
 */
export function getModuleExport(module: unknown): unknown {
    if (typeof module === 'object' && module !== null && 'default' in module) {
        return module.default;
    }
    return module;
}

export function airPlaySupported(): boolean {
    let hasAirPlay = false;
    if ((window as any).WebKitPlaybackTargetAvailabilityEvent == null || undefined) {
        return false;
    }
    window.addEventListener('webkitplaybacktargetavailabilitychanged', function(event: any) {
        switch (event.availability) {
            case "available":
                hasAirPlay = true;
                break;
            case "not-available":
                hasAirPlay = false;
                break;
        }
    });
    return hasAirPlay;
}
