
import { newATInternetPlugin, newGovoltePlugin, newPageTracker, type NPOTag, newTag } from '@npotag/tag'
import { type InitialisationProps } from '@npotag/tag/dist/types/src/npoTag'
import type NpoPlayer from '../../../npoplayer'

const npoTagPlugins = [
    newGovoltePlugin({ maxRetryCount: 5, delayBetweenRetriesInMs: 3000 }),
    newATInternetPlugin()
]

/**
* @function initPlayerTracker
* @description: This function initializes the NPO Tag instance and page tracker.
* The page tracker is a required prerequisite for the stream tracker to function.
* @param {NpoPlayer} npoplayer - The NpoPlayer instance.
* @param {InitialisationProps | null} _npotag - The NPO Tag instance.
* @param {NPOTag} _npotaginstance - The NPO Tag instance.
* @returns {void}
*/
export function initPlayerTracker (npoplayer: NpoPlayer, _npotag?: InitialisationProps | null, _npotaginstance?: NPOTag): void {
    npoplayer.npoTag = {
        npoTagInstance: undefined,
        pageTracker: null,
        heartbeatInterval: undefined
    }
    npoplayer.npoTag.npoTagInstance =
            _npotag != null
                ? newTag(_npotag, npoTagPlugins)
                : _npotaginstance
    if (npoplayer.npoTag.npoTagInstance != null) {
        npoplayer.npoTag.pageTracker = newPageTracker(
            npoplayer.npoTag.npoTagInstance
        )
    }
}
