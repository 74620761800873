/**
* @function logEvent
* @description Logs an event to the NPO Tag StreamTracker
* @param {Object} npoplayer - The NpoPlayer instance
* @param {string} event - The event name
* @param {Object} data - The event data, this is optional and currently only used for seek events, 
* because the tag SDK does not export SeekEventProps as a type the type for data is set to any.
* @returns {void}
*/

import NpoPlayer from "../../../npoplayer"

export function logEvent (npoplayer: NpoPlayer, event: string, data?: any): void {
    if (!npoplayer.player || !npoplayer.streamTracker) return;

    try {
        npoplayer.player.getCurrentTime()
    } catch (e) {
        console.log('Er gaat iets mis met de player API', e)
    }
    const streamOptions = { stream_position: npoplayer.player.getCurrentTime() }

    const eventHandlers: Record<string, (streamOptions: any) => void> = {
        start: npoplayer.streamTracker.start,
        buffering: npoplayer.streamTracker.buffering,
        buffering_complete: npoplayer.streamTracker.buffering_complete,
        complete: npoplayer.streamTracker.complete,
        fullscreen: npoplayer.streamTracker.fullscreen,
        load: npoplayer.streamTracker.load,
        load_complete: npoplayer.streamTracker.load_complete,
        pause: npoplayer.streamTracker.pause,
        resume: npoplayer.streamTracker.resume,
        stop: npoplayer.streamTracker.stop,
        windowed: npoplayer.streamTracker.windowed,
        time: npoplayer.streamTracker.time,
        // seek event including so properties
        seek: () => {
            npoplayer.streamTracker?.seek(data)
        }
    }

    const streamTrackerHandler = eventHandlers[event]
    npoplayer.logEmitter.emit('logEvent', event)
    streamTrackerHandler(streamOptions)
}
