import { type PlayerAPI, ViewMode } from "bitmovin-player";
import type NpoPlayer from '../../../npoplayer'

/*
* This function is called when a key is pressed and handles the logic for the keypress.
* @function resolveKeyPress
* @param {PlayerAPI} player - The Bitmovin player instance
* @param {NpoPlayer} npoplayer - The NpoPlayer instance
* @param {KeyboardEvent} e - The keyboard event
* @returns {void}
*/

export function resolveKeyPress(player: PlayerAPI, npoplayer: NpoPlayer, e: KeyboardEvent){
    switch(e.code) {
        case 'KeyM': {
            const isMuted = player.isMuted() ?? false;
            isMuted ? player.unmute() : player.mute();
            break;
        }
        case 'KeyF': {
            if (player.getViewMode() !== ViewMode.Fullscreen) {
                player.setViewMode(ViewMode.Fullscreen);
            } else {
                player.setViewMode(ViewMode.Inline);
            }
            break;
        }
        case 'Space': {
            const isPaused = player.isPaused() ?? false;
            isPaused ? player.play() : player.pause();
            break;
        }
        case 'ArrowUp': {
            const isMuted = player.isMuted() ?? false;
            if (isMuted) player.unmute();
            npoplayer.increaseVolume();
            break;
        }
        case 'ArrowDown': {
            const isMuted = player.isMuted() ?? false;
            if (isMuted) player.unmute();
            npoplayer.decreaseVolume();
            break;
        }
        case 'ArrowLeft': {
            npoplayer.goBackwards(10);
            break;
        }
        case 'ArrowRight': {
            npoplayer.goForward(10);
            break;
        }
    }
}