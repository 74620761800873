import {
    type SettingsPanel,
    Container,
    TitleBar,
    PictureInPictureToggleButton,
    AirPlayToggleButton,
    CastToggleButton,
    FullscreenToggleButton,
    SettingsToggleButton,
    VolumeToggleButton,
} from 'bitmovin-player-ui'
import { PlayerAPI, PlayerEvent } from 'bitmovin-player'
import { createSettingsButton } from './buttons'

export function createTopBar (player: PlayerAPI, settingsPanel: SettingsPanel) {
    const chromeCastButton = new CastToggleButton({
        hidden: true
    })

    player.on(PlayerEvent.CastAvailable, () => {
        if(player.isCastAvailable()) chromeCastButton.show()
    })

    return new TitleBar({
        components: [
            new Container({
                components: [
                    chromeCastButton,
                    new AirPlayToggleButton(),
                    createSettingsButton(settingsPanel),
                    // new SettingsToggleButton({ settingsPanel }),
                    new PictureInPictureToggleButton(),
                    new FullscreenToggleButton(),
                    new VolumeToggleButton(),
                ],
                cssClasses: ['controlbar-top', 'controlbar-small']
            })
        ],
        cssClasses: ['titlebar-small']
    })
}
