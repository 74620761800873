/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { type PlayerAPI } from 'bitmovin-player'

const interval = 10

export function rewind (player: PlayerAPI) {
    if (player.isLive()) {
        player.timeShift(player.getTimeShift() - interval)
    } else {
        player.seek(Math.max(0, player.getCurrentTime() - interval))
    }
}

export function forward (player: PlayerAPI) {
    if (player.isLive()) {
        player.timeShift(Math.min(0, player.getTimeShift() + interval))
    } else {
        player.seek(
            Math.min(
                player.getDuration(),
                player.getCurrentTime() + interval
            )
        )
    }
}
