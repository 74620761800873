import type NpoPlayer from '../../npoplayer'
import {
    UIContainer,
    PlaybackToggleOverlay,
    BufferingOverlay,
    CastStatusOverlay,
    SubtitleOverlay,
    ErrorMessageOverlay
} from 'bitmovin-player-ui'
import { createMiddleButtons } from './components/buttons'
import { createPlayNextScreen } from './components/playnext'
import { createSettingsPanel } from './components/settingspanel'
import { createCTABar } from './components/ctabar'
import { createTopBar } from './components/topbar'
import { createControlBar } from './components/controlbar'
import { createTitleBar } from './components/titlebar'
import { PlayerAPI } from 'bitmovin-player'

export function createUIContainer (npoplayer: NpoPlayer, player: PlayerAPI, variant: string) {
    let cssClassArray = ['npo-player']
    let uiDelay:number = 3000

    if(variant === 'default') {
        uiDelay = 3000
    } else if(variant === 'ad'){
        uiDelay = -1
        cssClassArray.push('ster-ad')
    }

    const middleButtons = createMiddleButtons(player)
    const playNextScreen = createPlayNextScreen(player, npoplayer)
    const settingsPanel = createSettingsPanel(npoplayer)
    const ctaBar = createCTABar(player, npoplayer, variant)
    const topBar = createTopBar(player, settingsPanel)
    const controlBar = createControlBar(
        npoplayer,
        settingsPanel,
    )
    const titleBar = createTitleBar(npoplayer)

    const errorMessageOverlay = new ErrorMessageOverlay()
    npoplayer.uiComponents.errorMessageOverlay = errorMessageOverlay

    const baseComponentsBefore = [
        new SubtitleOverlay(),
        new BufferingOverlay(),
        new PlaybackToggleOverlay(),
        new CastStatusOverlay(),
        playNextScreen,
        middleButtons,
    ]

    const baseComponentsAfter = [
        errorMessageOverlay
    ]

    let conditionalComponents = [];

    // only add top bar if variant = default
    if (variant === 'default') {
        conditionalComponents.push(controlBar);
        conditionalComponents.push(ctaBar);
        conditionalComponents.push(titleBar);
        conditionalComponents.push(topBar);
    }

    if (variant === 'ad') {
        conditionalComponents.push(controlBar);
        conditionalComponents.push(ctaBar);
    }

    return new UIContainer({
        components: [...baseComponentsBefore, ...conditionalComponents, ...baseComponentsAfter],
        cssClasses: cssClassArray,
        hideDelay: uiDelay
    })
}

