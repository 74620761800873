import { Container } from 'bitmovin-player-ui'
import { createPlayNextButton } from './buttons'
import { showPlayNextScreen } from '../shared/playnextscreen'

// TODO: find explicit type for window
declare const window: any;

export function createPlayNextScreen () {
    const playNextButton = createPlayNextButton()

    if (window.bitmovin.customMessageHandler) {
        window.bitmovin.customMessageHandler.on('showPlayNextScreen', () => {
            showPlayNextScreen(window.bitmovin.customMessageHandler.sendAsynchronous('doPlayNext'));
        })
    }

    return new Container({
        components: [playNextButton],
        cssClasses: ['overlay-playnext']
    })
}
