import {
    SubtitleListBox,
    ListBox,
    SettingsPanel,
    SettingsPanelPage,
    SettingsPanelPageOpenButton,
    SettingsPanelPageBackButton,
    Button,
    Label,
    SettingsPanelItem,
    AudioQualitySelectBox,
    AudioTrackSelectBox
} from 'bitmovin-player-ui'
import { localize } from '../../utilities/utilities'
import type NpoPlayer from '../../../npoplayer'
import {
    handleSubtitleListBoxItemSelected,
    handleSpeedListBoxItemSelected,
    handleQualityListBoxItemSelected
} from '../handlers/listboxhandlers'
import { PlayerAPI } from 'bitmovin-player'

export function createSettingsPanel (npoplayer: NpoPlayer | PlayerAPI) {
    const player = ('player' in npoplayer) ? npoplayer.player : npoplayer as PlayerAPI;

    const subtitleListBox = new SubtitleListBox()
    const speedListBox = new ListBox({
        items: [
            { key: '0.25', label: '0.25x' },
            { key: '0.5', label: '0.5x' },
            { key: '1', label: '1x' },
            { key: '1.5', label: '1.5x' },
            { key: '2', label: '2x' }
        ]
    })
    const qualityListBox = new ListBox()

    const settingsLabel = localize('settings')
    const videoqualityLabel = localize('settings.video.quality')
    const speedLabel = localize('speed')
    const subtitlesLabel = localize('settings.subtitles')
    const audioqualityLabel = localize('settings.audio.quality')
    const audiotrackLabel = localize('settings.audio.track')
    const autoLabel = localize('auto')

    //define class strings to avoid duplication
    const settingTriggerClass = 'settings-trigger';
    const settingsBackButtonClass = 'settings-back-button';
    const listboxPanelClass = 'listbox-panel';
    const listboxPagerButtonClass = 'listbox-pager-button';

    const subtitlePanelPage = new SettingsPanelPage({
        components: [
            new Button({ cssClass: settingTriggerClass  }),
            new SettingsPanelItem('', subtitleListBox)
        ],
        cssClasses: [listboxPanelClass]
    })

    const speedPanelPage = new SettingsPanelPage({
        components: [
            new Button({ cssClass: settingTriggerClass  }),
            new SettingsPanelItem('', speedListBox)
        ],
        cssClasses: [listboxPanelClass]
    })

    const qualityPanelPage = new SettingsPanelPage({
        components: [
            new Button({ cssClass: settingTriggerClass  }),
            new SettingsPanelItem('', qualityListBox)
        ],
        cssClasses: [listboxPanelClass]
    })

    const settingsTriggerButton = new Button({ cssClass: settingTriggerClass  })

    const mainSettingsPage = new SettingsPanelPage({
        components: [
            settingsTriggerButton,
            new Label({ text: settingsLabel, cssClass: 'setting-header' }),
            new SettingsPanelItem(
                audioqualityLabel,
                new AudioQualitySelectBox()
            ),
            new SettingsPanelItem(audiotrackLabel, new AudioTrackSelectBox())
        ],
        cssClasses: ['main-panel']
    })

    mainSettingsPage.onActive.subscribeOnce(() => {
        qualityListBox.addItem('auto', autoLabel)
        player?.getAvailableVideoQualities().forEach(quality => {
            qualityListBox.addItem(quality.id, quality.label ?? 'test')
        })
        qualityListBox.selectItem('auto')
    })

    const settingsPanel = new SettingsPanel({
        components: [
            mainSettingsPage,
            subtitlePanelPage,
            speedPanelPage,
            qualityPanelPage
        ],
        hidden: true,
        pageTransitionAnimation: false
    })

    const subtitleSettingsOpenButton = new SettingsPanelPageOpenButton({
        targetPage: subtitlePanelPage,
        container: settingsPanel,
        text: 'Nederlands',
        cssClasses: [listboxPagerButtonClass]
    })

    const speedSettingsOpenButton = new SettingsPanelPageOpenButton({
        targetPage: speedPanelPage,
        container: settingsPanel,
        text: '1x',
        cssClasses: [listboxPagerButtonClass]
    })

    const qualitySettingsOpenButton = new SettingsPanelPageOpenButton({
        targetPage: qualityPanelPage,
        container: settingsPanel,
        text: 'automatisch',
        cssClasses: [listboxPagerButtonClass]
    })

    const subtitlesPanelItem = new SettingsPanelItem(
        subtitlesLabel,
        subtitleSettingsOpenButton,
        { hidden: false }
    )
    const speedPanelItem = new SettingsPanelItem(
        speedLabel,
        speedSettingsOpenButton
    )
    const qualityPanelItem = new SettingsPanelItem(
        videoqualityLabel,
        qualitySettingsOpenButton,
        { hidden: false }
    )

    mainSettingsPage.addComponent(subtitlesPanelItem)
    mainSettingsPage.addComponent(speedPanelItem)
    mainSettingsPage.addComponent(qualityPanelItem)

    subtitlePanelPage.addComponent(
        new SettingsPanelPageBackButton({
            targetPage: mainSettingsPage,
            container: settingsPanel,
            text: 'Ondertiteling',
            cssClasses: [settingsBackButtonClass]
        })
    )

    speedPanelPage.addComponent(
        new SettingsPanelPageBackButton({
            targetPage: mainSettingsPage,
            container: settingsPanel,
            text: 'Snelheid',
            cssClasses: [settingsBackButtonClass]
        })
    )

    qualityPanelPage.addComponent(
        new SettingsPanelPageBackButton({
            targetPage: mainSettingsPage,
            container: settingsPanel,
            text: 'Kwaliteit',
            cssClasses: [settingsBackButtonClass]
        })
    )

    if (('uiComponents' in npoplayer)) {
        npoplayer.uiComponents.subtitlesButton = subtitlesPanelItem
        npoplayer.uiComponents.qualityButton = qualityPanelItem
    }

    subtitleListBox.onItemSelected.subscribe(
        handleSubtitleListBoxItemSelected(
            subtitleSettingsOpenButton,
            settingsPanel,
            mainSettingsPage
        )
    )

    if (player) {
        speedListBox.selectItem('1')
        speedListBox.onItemSelected.subscribe(
            handleSpeedListBoxItemSelected(
                player,
                speedSettingsOpenButton,
                settingsPanel,
                mainSettingsPage
            )
        )
        qualityListBox.selectItem('auto')
        qualityListBox.onItemSelected.subscribe(
            handleQualityListBoxItemSelected(
                player,
                qualitySettingsOpenButton,
                settingsPanel,
                mainSettingsPage
            )
        )
    }

    return settingsPanel
}
