import { type PlayerAPI } from 'bitmovin-player'
import { Button, PlaybackToggleButton, ControlBar } from 'bitmovin-player-ui'
import type NpoPlayer from '../../../npoplayer'
import { rewind, forward } from '../handlers/timecontrolhandlers'

export function createMiddleButtons (player: PlayerAPI) {
    const bigPlayToggleButton = new PlaybackToggleButton({
        cssClass: 'ui-playbacktogglebutton'
    })
    const bigRewindButton = createRewindButton(player)
    const bigForwardButton = createForwardButton(player)

    return new ControlBar({
        components: [bigRewindButton, bigPlayToggleButton, bigForwardButton],
        cssClasses: ['controlbar-middle']
    })
}

export function createRewindButton (player: PlayerAPI | null) {
    const rewindButton = new Button({
        cssClass: 'ui-rewindbutton bmpui-ui-button'
    })

    if (player) {
        rewindButton.onClick.subscribe(() => {
            rewind(player)
        })
    }

    return rewindButton
}

export function createForwardButton (player: PlayerAPI | null) {
    const forwardButton = new Button({
        cssClass: 'ui-forwardbutton bmpui-ui-button'
    })

    if (player) {
        forwardButton.onClick.subscribe(() => {
            forward(player)
        })
    }
    
    return forwardButton
}

export function createPlayNextButton (
    player: PlayerAPI,
    npoplayer: NpoPlayer
) {
    const playNextButton = new Button({
        cssClass:
            'ui-hugeplaynextbacktogglebutton ui-playNextButton bmpui-ui-button',
        text: 'De volgende aflevering wordt zo afgespeeld...',
        hidden: false
    })
    playNextButton.onClick.subscribe(() => {
        if (player != null) {
            npoplayer.doPlayNext()
        }
    })
    return playNextButton
}

export function createskipIntroButton (player: PlayerAPI) {
    const skipIntroButton = new Button({
        cssClass:
            'ui-textbutton ui-skipintrobutton bmpui-ui-button bmpui-hidden',
        text: 'Intro overslaan',
        hidden: true
    })
    skipIntroButton.onClick.subscribe(() => {
        if (player != null) {
            // if (introDataAvailable) {
            // TODO Check if metadata has intro data, this is currently not yet available in the API, so this button is merely scaffolding for now.
            player.seek(96)
            // }
        }
    })
    return skipIntroButton
}

export function createGoBackLiveButton (player: PlayerAPI) {
    const goBackLiveButton = new Button({
        cssClass: 'ui-textbutton ui-backtolivebutton bmpui-ui-button',
        text: 'Terug naar live',
        hidden: true
    })
    goBackLiveButton.onClick.subscribe(() => {
        if (player != null && player.isLive()) {
            player.timeShift(0)
        }
    })
    return goBackLiveButton
}

export function createWatchFromStartButton (
    player: PlayerAPI,
    npoplayer: NpoPlayer
) {
    const watchFromStartButton = new Button({
        cssClass: 'ui-textbutton ui-watchfromstartbutton bmpui-ui-button',
        text: 'Kijk vanaf het begin',
        hidden: true
    })

    watchFromStartButton.onClick.subscribe(() => {
        if ((player.isLive()) ?? false) npoplayer.watchFromStart()
        if (npoplayer.uiComponents.watchFromStartButton) npoplayer.uiComponents.watchFromStartButton.hide()
    })

    npoplayer.uiComponents.watchFromStartButton = watchFromStartButton

    return watchFromStartButton
}
