import { ErrorUtils } from "bitmovin-player-ui";

// Reference: https://bitmovin.com/docs/player/api-reference/web/web-sdk-api-reference-v8#/player/web/8/docs/enums/core_deficiency.errorcode.html#network_error
export const customSpecificErrorMessageOverlayConfig = {
    // Overwrite error 1000 'Error is unknown'
    1000: "Oeps, er gaat iets mis",

    // API_NOT_AVAILABLE
    // The player API was accessed after the player instance has been destroyed by either calling PlayerAPI.destroy or after an PlayerEvent.Error event has been thrown.
    1001: "Oeps, er gaat iets mis",

    // SETUP_NO_HTML_ELEMENT
    // An attempt was made to create a player instance with a container element that is not an instance of HTMLElement.
    1101: "De player is niet aangemaakt",

    // SETUP_MISSING_CONFIGURATION
    // No PlayerConfig was provided when attempting to create a player instance.
    1102: "Er ging iets mis met de configuratie",

    // SETUP_LICENSE_ERROR
    // The player license server did not grant playback for the given player key as specified in the PlayerConfig.
    1103: "Er is iets mis met de licentie (access key)",

    // SETUP_MISSING_DOMAIN_LICENSE_ALLOWLIST
    // The player build is domain-locked and not authorized to play content on the current domain.
    1104: "De video kan niet worden afgespeeld op dit domein",

    // SETUP_MISSING_LICENSE_ALLOWLIST
    // The player is not allowed to play back content on the current domain.
    1105: "De video kan niet op dit web-adres worden afgespeeld",

    // SETUP_INVALID_LICENSE_SERVER
    // The license server URL provided in TweaksConfig.licenseServer does not match any of the available license server URLs.
    1106: "",

    // SETUP_INVALID_IMPRESSION_SERVER
    // The impression server URL provided in TweaksConfig.impressionServer does not match any of the available impression server URLs.
    1107: "",

    // SETUP_NO_RENDERING_ENGINE
    // Could not initialize the rendering engine. This error occurs when the platform does not support the PlayerType and StreamType for the provided SourceConfig on the current platform, and is therefore unable to instantiate a suitable rendering engine.
    1108: "",

    // SETUP_UNSUPPORTED_PROTOCOL`
    // This site has been loaded using "file" protocol which is not supported. Please host the page using a web server (using http or https).
    1113: "De video kan niet worden afgespeeld zonder web server",

    // SOURCE_INVALID
    // The SourceConfig provided to the PlayerAPI.load method is invalid. Ensure that the SourceConfig contains at least one valid source URL for any of the supported stream types on the current platform.
    1201: "Er is iets mis met de source configuratie",

    // Transform error 1202 'Unsupported manifest format' to uppercase
    1202: (error: any) => {
        return ErrorUtils.defaultErrorMessages[error.code];
    },

    // Overwrite error 1203 = SOURCE_NO_SUPPORTED_TECHNOLOGY
    // The platform does not support any of the technologies needed to play back the provided source.
    1203: "Helaas, deze video is niet beschikbaar.",

    // SOURCE_STREAM_TYPE_NOT_SUPPORTED
    // The stream type is not supported.
    // This error happens when the SourceConfig does not contain a valid stream type, i.e. dash, hls, progressive or smooth.
    1204: "",

    // SOURCE_FORCED_TECHNOLOGY_NOT_SUPPORTED
    // The Technology selected through the forceTechnology parameter, that was passed to the PlayerAPI.load method, is not supported on the current platform.
    1205: "",

    // SOURCE_NO_STREAM_FOUND_FOR_SUPPORTED_TECHNOLOGY
    // The current platform doesn't support any of the technologies needed to play back any of the sources provided in the SourceConfig.
    1206: "",

    // SOURCE_EMPTY_SEGMENT
    // The downloaded segment is empty.
    1207: "",

    // SOURCE_COULD_NOT_LOAD_MANIFEST
    // The manifest could not be loaded.
    // The manifest network request failed, please check the network request's response for the failure reason.
    1208: (error: any) => {
        const statusCode = error.data.statusCode;
        return "Manifest laden mislukt met HTTP fout " + statusCode;
    },

    // SOURCE_PROGRESSIVE_STREAM_ERROR
    // The specified progressive stream type is not supported, or the SourceElement used to load the source has reported an error.
    1209: "",

    // SOURCE_HLS_STREAM_ERROR
    // An error was encountered while playing, or trying to play back an HLS stream.
    1210: "",

    // SOURCE_ENCRYPTION_METHOD_NOT_SUPPORTED
    // The encryption method used by the source is not supported.
    1211: "",

    // PLAYBACK_VIDEO_DECODING_ERROR
    // An error occurred while trying to demux or decode the content.
    // This error can occur when the browser or device is unable to either demux or decode the content.
    1301: "",

    // PLAYBACK_HLS_COULD_NOT_LOAD_TRANSMUXER
    // The transmuxer could not be initialized.
    // This error can occur when an error is encountered during transmuxer initialization.
    1304: "",

    // NETWORK_ERROR
    // A general network error has occurred while downloading resource E.g.: the manifest or a segment of the stream.
    1400: "Er is iets mis met uw internet-verbinding",

    // NETWORK_MANIFEST_DOWNLOAD_TIMEOUT
    // The manifest download request timed out.
    1401: "",

    // NETWORK_SEGMENT_DOWNLOAD_TIMEOUT
    // The segment download timed out.
    1402: "",

    // NETWORK_PROGRESSIVE_STREAM_DOWNLOAD_TIMEOUT
    // The progressive stream download timed out.
    1403: "",

    // NETWORK_FAILED_CERTIFICATE_REQUEST
    // The Fairplay DRM certificate request failed.
    1404: "",

    // DRM_MISSING_CONFIGURATION
    // An attempt was made to play back a Fairplay protected stream, but no DRM configuration was provided in the SourceConfig.drm.
    2001: "",

    // DRM_NO_LICENSE_SERVER_URL_PROVIDED
    // The Fairplay licensing server URL is missing in the provided AppleFairplayDRMConfig.
    2002: "",

    // DRM_FAILED_LICENSE_REQUEST
    // The DRM license request failed.
    2003: "Oeps, er is iets mis met het opvragen van de DRM",

    2005: "",

    // DRM_NO_KEY_SYSTEM
    // The current platform doesn't support any of the key systems specified in the SourceConfig that are required to play back the content.
    2006: "Oeps, er is iets mis met de DRM",

    // DRM_KEY_SESSION_INITIALIZATION_FAILED
    // Unable to create or initialize a DRM key session.
    2007: "",

    // DRM_MEDIA_KEY_INITIALIZATION_FAILED
    // The DRM Media Keys object could not be created or initialized.
    2008: "",

    // DRM_KEY_ERROR
    // The video element or an associated DRM Key Session has thrown a DRM key error.
    2009: "",

    // DRM_KEY_SYSTEM_NOT_SUPPORTED
    // No supported Fairplay Key System is available.
    2010: "",

    // DRM_CERTIFICATE_ERROR
    // The DRM certificate specified in the DRMConfig is not valid, the player was unable to parse the provided certificate or the network request for the certificate failed.
    2011: "",

    // DRM_PLAYREADY_INVALID_HEADER_KEY_VALUE_PAIR
    // Invalid header key/value pair encountered while parsing a PlayReady license request.
    2012: "",

    // DRM_RESTRICTED_OUTPUT
    // The DRM protected content cannot be played back due to the device not being able to securely present the content.
    2013: "",

    // DRM_INIT_DATA_MISSING
    // No init data was found for the used key system in the manifest or the segments.
    2015: "",

    // VR_INCOMPATIBLE_PLAYER_TECHNOLOGY
    // The video element or an associated DRM Key Session has thrown a DRM key error.
    2101: "",

    // MODULE_INVALID_DEFINITION
    // The definition of the module is invalid
    3001: "",

    // MODULE_INVALID_DEFINITION_DEPENDENCY
    // The module definition specifies dependencies but the module is not provided via a function for deferred loading.
    3002: "",

    // MODULE_MISSING
    // A module cannot be loaded because it has not been added to the player core.
    3003: "",

    // MODULE_DEPENDENCY_MISSING
    // A module cannot be loaded because one or more dependencies are missing.
    3004: "",

    // SEGMENT_PSSH_DATA_MISSING
    // No relevant PSSH block data was found for the used key system. This issue is known to happen on Tizen 2016.
    4000: "",
};
