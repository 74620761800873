import { Container } from 'bitmovin-player-ui'
import { createWatchFromStartButton, createGoBackLiveButton } from '../nativemobile/buttons'
import { type PlayerAPI } from 'bitmovin-player'

export function createCTABar (player: PlayerAPI) {
    const goBackLiveButton = createGoBackLiveButton(player)
    const watchFromStartButton = createWatchFromStartButton()

    return new Container({
        components: [watchFromStartButton, goBackLiveButton],
        cssClasses: ['controlbar-textbuttons']
    })
}
