import { type PlayerAPI, PlayerEvent, ViewMode } from 'bitmovin-player'
import { logEvent } from './eventlogging'
import type NpoPlayer from '../../../npoplayer'

/**
* @function bindPlayerEvents
* @description Binds the player events to the event logging function.
* @param {NpoPlayer} npoplayer - The NpoPlayer instance.
* @param {PlayerAPI} player - The Bitmovin player instance.
* @returns {void}
*/
export function bindPlayerEvents (npoplayer: NpoPlayer, player: PlayerAPI): void {
    if (player == null) return

    let isNewSource = false;

    const playerEvents = [
        { event: PlayerEvent.Paused, name: 'pause' },
        { event: PlayerEvent.SourceLoaded, name: 'load_complete' },
        { event: PlayerEvent.Ready, name: 'load_complete' },
        { event: PlayerEvent.StallStarted, name: 'buffering' },
        { event: PlayerEvent.StallEnded, name: 'buffering_complete' },
        { event: PlayerEvent.PlaybackFinished, name: 'complete' },
        { event: PlayerEvent.TimeChanged, name: 'time' },
        {
            event: PlayerEvent.ViewModeChanged,
            name:
                    player.getViewMode() === ViewMode.Fullscreen
                        ? 'fullscreen'
                        : 'windowed'
        },
        { event: PlayerEvent.SourceUnloaded, name: 'stop' },
        { event: PlayerEvent.Destroy, name: 'stop' },
    ]

    const logEventHandler = (npoplayer: NpoPlayer, eventName: string) => {
        return () => {
            logEvent(npoplayer, eventName);
        };
    };

    //Handle seek events, this requires a custom handler because we need to send the seek_from and seek_position parameters
    const seekHandler = (e: any) => {
        const data = {
            seek_from: e.position,
            stream_position: e.seekTarget
        }
        logEvent(npoplayer, 'seek', data)
    }

    // Because we only want to load 'start' when a new source is starting playback, we need to keep track of that
    const handleSourceLoaded = () => {
        isNewSource = true;
    };

    //fire resume event when the player resumes playback and the source is not new (unpause)
    const handlePlay = () => {
        if (!isNewSource) {
            logEvent(npoplayer, 'resume');
        }
    };

    //fire start event when the player starts playing new content, set isNewSource to false
    const handlePlaying = () => {
        if (isNewSource) {
            logEvent(npoplayer, 'start');
            isNewSource = false;
        }
    };

    //sends a stop event when the user leaves the page
    function stopBeforeUnload(): void {
        logEvent(npoplayer, 'stop')
    }

    //remove old listeners (if they exist) before setting new ones
    player.off(PlayerEvent.SourceLoaded, handleSourceLoaded)
    player.off(PlayerEvent.Seek, seekHandler)
    player.off(PlayerEvent.Play, handlePlay);
    player.off(PlayerEvent.Playing, handlePlaying);
    playerEvents.forEach(({ event, name }) => {
        const handler = logEventHandler(npoplayer, name);
        player.off(event, handler);
    });
    window.removeEventListener('beforeunload', stopBeforeUnload);

    //bind new listeners
    player.on(PlayerEvent.SourceLoaded, handleSourceLoaded);
    player.on(PlayerEvent.Seek, seekHandler)
    player.on(PlayerEvent.Play, handlePlay);
    player.on(PlayerEvent.Playing, handlePlaying);
    playerEvents.forEach(({ event, name }) => {
        const handler = logEventHandler(npoplayer, name);
        player.on(event, handler);
    });
    window.addEventListener('beforeunload', stopBeforeUnload);
}
