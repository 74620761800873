/*
* This file contains the DRM verification logic.
* It checks if the DRM token is expired and if so, it will request a new token.
* If there is no DRM token present, the function will skip the verification flow entirely.
* If the token is still valid, it will load the stream as normal.
* @function verifyDRM
* @param {NpoPlayer} npoplayer - The NpoPlayer instance
* @param {PlayerAPI} player - The Bitmovin player instance
* @param {ApiPayload} payload - The payload object
* @returns {void}
*/
import { jwtToBase64, base64ToObject } from '../utilities/utilities'
import { getStreamObject } from '../api/getstreamobject'
import type NpoPlayer from '../../npoplayer'
import { type ApiPayload } from '../../types/interfaces'
import type { PlayerAPI } from 'bitmovin-player'

export async function verifyDRM (npoplayer: NpoPlayer, player: PlayerAPI, payload: ApiPayload) {
    if (npoplayer.streamObject.stream.drmToken != null) {
        const drmBase64 = jwtToBase64(npoplayer.streamObject.stream.drmToken)
        const drmJsonTimestamp = base64ToObject(drmBase64).iat
        const currentTime: number = (Date.now() / 1000) | 0

        if (drmJsonTimestamp >= currentTime) {
            void player.load(npoplayer.sourceConfig)
        } else {
            // Token is expired, entering fallback flow
            console.error('Er ging iets mis. Item wordt herladen.')

            const backupStreamObject = await getStreamObject(npoplayer, payload)
            const newDrmToken = backupStreamObject.stream.drmToken

            // Cloning the streamObject and parsing it so we can manipulate the JSON
            const clonedStreamObject = JSON.parse(
                JSON.stringify(npoplayer.streamObject)
            )
            clonedStreamObject.stream.drmToken = newDrmToken

            npoplayer.streamObject = clonedStreamObject
            void player.load(npoplayer.sourceConfig)
        }
    } else {
        void player.load(npoplayer.sourceConfig)
    }
}
