// listboxHandlers.ts
import { type PlayerAPI } from 'bitmovin-player'
import {
    type SettingsPanel,
    type SettingsPanelPage,
    type SettingsPanelPageOpenButton
} from 'bitmovin-player-ui'

function closeSettingsPanel(settingsPanel: SettingsPanel, mainSettingsPage: SettingsPanelPage) {
    setTimeout(() => {
        if(settingsPanel.getActivePage() !== mainSettingsPage) settingsPanel.setActivePage(mainSettingsPage)
    }, 100);
}

export function handleSubtitleListBoxItemSelected (
    subtitleSettingsOpenButton: SettingsPanelPageOpenButton,
    settingsPanel: SettingsPanel,
    mainSettingsPage: SettingsPanelPage
) {
    return (e: any) => {
        e.getItems().forEach((item: any) => {
            if (item.key === e.getSelectedItem()) {
                subtitleSettingsOpenButton.setText(item.label)
            }
        })
        closeSettingsPanel(settingsPanel, mainSettingsPage);
    }
}

export function handleSpeedListBoxItemSelected (
    player: PlayerAPI,
    speedSettingsOpenButton: SettingsPanelPageOpenButton,
    settingsPanel: SettingsPanel,
    mainSettingsPage: SettingsPanelPage
) {
    return (source: any, args: any) => {
        source.getItems().forEach((item: any) => {
            if (item.key === args) {
                player.setPlaybackSpeed(parseFloat(item.key))
                speedSettingsOpenButton.setText(item.label)
                source.selectItem(item.key)
            }
        })
        closeSettingsPanel(settingsPanel, mainSettingsPage);
    }
}

export function handleQualityListBoxItemSelected (
    player: PlayerAPI,
    qualitySettingsOpenButton: SettingsPanelPageOpenButton,
    settingsPanel: SettingsPanel,
    mainSettingsPage: SettingsPanelPage
) {
    return (source: any, args: any) => {
        source.getItems().forEach((item: any) => {
            if (item.key === args) {
                player.setVideoQuality(item.key)
                qualitySettingsOpenButton.setText(item.label)
                source.selectItem(item.key)
            }
        })
        closeSettingsPanel(settingsPanel, mainSettingsPage);
    }
}
