import { PlayerAPI } from "bitmovin-player";
import { UIComponents } from "../../../types/interfaces";

/*
* This function handles the error flow.
* @function handlePlayerError
* @param {PlayerAPI} player - The Bitmovin player instance
* @param {UIComponents} uiComponents - The UIComponents object
* @param {any} input - The input to parse to the error message
* @returns {void}
*/

export function handlePlayerError(
    player: PlayerAPI,
    uiComponents: UIComponents,
    input: any
) {
    let message = '';

    if (typeof input === 'string') {
        message = input;
    }

    console.error('Er is iets mis met het ophalen van de stream.', input);

    player.unload();

    if (uiComponents.controlbar) uiComponents.controlbar.hide();

    if (uiComponents.errorMessageOverlay == null) return;
    uiComponents.errorMessageOverlay.show();
    (uiComponents.errorMessageOverlay as any).errorLabel.setText(
        message ?? 'De video kan niet worden geladen'
    );
}
