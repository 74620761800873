export function isSafari (isFairPlayDrmSupported: boolean) {   
    const isSafariUserAgent =
            navigator.userAgent.includes('Safari') &&
            !navigator.userAgent.includes('Chrome') &&
            !navigator.userAgent.includes('IEMobile') &&
            !navigator.userAgent.includes('Edge') &&
            !navigator.userAgent.includes('Chromium') &&
            !navigator.userAgent.includes('Android')

    return isSafariUserAgent || isFairPlayDrmSupported
}
