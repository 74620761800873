import { PlayerEvent, type PlayerAPI } from 'bitmovin-player'
import type NpoPlayer from '../../../npoplayer'
import { type StreamOptions } from 'types/interfaces'

/*
* This function seeks the player to the specified offset on a VOD,
* if the player is playing live content it will do nothing and parse a warn to the console.
* @function handleStartOffset
* @param {PlayerAPI} player - The Bitmovin player instance
* @param {number} offset - The offset to seek to in seconds
* @returns {void}
*/
export function handleStartOffset (player: PlayerAPI, offset?: number) {    
    if (offset == null || player.isLive()) {
        if (player.isLive()) {
            console.warn('handleStartOffset was called but the player is playing live content.')
        }
        return
    }
    player.on(PlayerEvent.SourceLoaded, () => {
        player.seek(offset)
    })
}

/*
* This function rewinds a livestream to the specified unix timestamp,
* if the player is playing VOD content it will do nothing and parse a warn to the console.
* @function shiftToProgramStart
* @param {PlayerAPI} player - The Bitmovin player instance
* @param {number} timestamp - The unix timestamp to rewind to
* @returns {void}
*/
export function shiftToProgramStart (player: PlayerAPI | null, timestamp?: number) {
    if (player == null) return
    if (timestamp == null || !player.isLive()) {
        if (!player.isLive()) {
            console.warn('shiftToProgramStart was called but the player is playing VOD content.')
        }
        return
    }
    player.timeShift(timestamp)
}

/*
* This functions handles the logic flow for showing the Watch from start button.
* If liveOffset is set to true, it will hide the button and rewind the player to the start of the program on load.
* @function handleLiveOffsetLogic
* @param {NpoPlayer} npoplayer - The NpoPlayer instance
* @param {PlayerAPI} player - The Bitmovin player instance
* @param {StreamOptions} options - The stream options object
* @returns {void}
*/

export function handleLiveOffsetLogic (npoplayer: NpoPlayer, player: PlayerAPI | null, options: StreamOptions) {
    if (player == null) return
    if (npoplayer.uiComponents.watchFromStartButton == null) return
    if (options?.liveProgramTime == null) {
        npoplayer.uiComponents.watchFromStartButton.hide()
        return
    }

    // set the value present in streamoptions on the npoplayer instance to supplement the button click handler
    npoplayer.streamOptions.liveProgramTime = options.liveProgramTime

    if (player.isLive() && options.liveProgramTime > 0 ) {
        npoplayer.uiComponents.watchFromStartButton.show()
    } else {
        npoplayer.uiComponents.watchFromStartButton.hide()
    }

    if (options.liveOffset !== null && options.liveOffset !== false && options.liveProgramTime > 0) {
        npoplayer.uiComponents.watchFromStartButton.hide()
        shiftToProgramStart(player, options.liveProgramTime)
    }
}
