import { PlayerAPI, PlayerEvent } from 'bitmovin-player'
import { UIManager } from 'bitmovin-player-ui'
import { checkFunction, seekFunction } from './setfragments'

export async function removeFragments (player: PlayerAPI, uiManager: UIManager) {
    uiManager.getTimelineMarkers().forEach(marker => {
        uiManager.removeTimelineMarker(marker)
    })

    player.off(PlayerEvent.TimeChanged, checkFunction())
    player.off(PlayerEvent.Seek, seekFunction())
}