/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Container } from 'bitmovin-player-ui'
import { createPlayNextButton } from '../components/buttons'
import { type PlayerAPI } from 'bitmovin-player'
import type NpoPlayer from '../../../npoplayer'

export function createPlayNextScreen (
    player: PlayerAPI,
    npoplayer: NpoPlayer
) {
    const playNextButton = createPlayNextButton(player, npoplayer)

    return new Container({
        components: [playNextButton],
        cssClasses: ['overlay-playnext']
    })
}
