/*
* adlabel.ts
* This file contains the code for the adlabel component used to display to the user how many ads will be played when an adBreak occurs.
*/

import { Label} from 'bitmovin-player-ui'
import type NpoPlayer from '../../../npoplayer'

/*
* @function createAdLabel
* @param {NpoPlayer} npoplayer - The player instance
* @returns {Label} - The ad label
*/
export function createAdLabel (npoplayer: NpoPlayer) {
    npoplayer.uiComponents.adlabel = undefined
    const adLabel = new Label({ text: '', cssClass: 'adLabel', hidden: true })
    npoplayer.uiComponents.adlabel = adLabel
    return adLabel
}